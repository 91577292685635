import { useLocalObservable } from "mobx-react-lite";
import React from "react";
import PropTypes from "prop-types";
import StreetMapStore, { StreetMapStoreContext } from "../Stores/StreetMapStore";
interface IStreetMapProvider {}

const StreetMapProvider: React.FC<IStreetMapProvider> = (props) => {
	const localStore = useLocalObservable(() => new StreetMapStore());
	return <StreetMapStoreContext.Provider value={localStore}>{props.children}</StreetMapStoreContext.Provider>;
};

StreetMapProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
export default StreetMapProvider;
