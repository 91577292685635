import React from "react";

import { useHistory } from "react-router-dom";

import {
    Typography,
    makeStyles,
    useTheme,
    Container,
} from "@material-ui/core";

import Manual from "./Manual.pdf";
import Header from "../Dashboard/Header";

const useStyles = makeStyles((theme) => ({
    toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
    container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
    appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	manual: {
		height: "80vh",
		width: "100%",
		marginTop: '12px'
	}
}));

function Instructions() {
    const classes = useStyles();
    const theme = useTheme();

	const history = useHistory();

	if (!sessionStorage.getItem("userId")) {
		history.goBack();
	}

    return (
        <>
			<Header />

            <main className={classes.content}>
				<div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
					<Typography
						variant="h4"
						style={{
							fontSize: "25px",
							paddingTop: "3px",
						}}
					>
						Instructions
					</Typography>
					<object data={Manual} className={classes.manual} />
                </Container>
            </main>
        </>
    );
}

export default Instructions;