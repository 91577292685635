import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import {
	Drawer,
	AppBar,
	Toolbar,
	List,
	ListItem,
	Typography,
	Divider,
	IconButton,
	CssBaseline,
	makeStyles,
	useTheme,
	Grid,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import clsx from "clsx";

import Navigation from "../Navigation/Navigation";
import AuthProvider from "../../Providers/AuthProvider";
import { useAuthStore } from "../../Stores/Stores";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
	user: {
		flexGrow: 1,
		fontSize: "14px !important",
	},
	logoutButton: {
		backgroundColor: "transparent !important",
	},
	logout: {
		flexGrow: 1,
		fontSize: "14px !important",
		color: "#ffffff !important",
	},
	divider: {
		backgroundColor: "#ffffff", 
		marginLeft: "12px !important",
		marginTop: "24px !important",
		marginBottom: "24px !important",
	},
}));

const Header = () => {
	const classes = useStyles();
	const theme = useTheme();

	const authStore = useAuthStore();
	const history = useHistory();

	const logout = async () => {
		await authStore?.logout();
		sessionStorage.removeItem("userId");
		sessionStorage.removeItem("token");
		sessionStorage.removeItem("userRole");
		sessionStorage.removeItem("userName");

		history.push("/login");
	};

	const [open, setOpen] = useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	let userRole = "";
	const role = sessionStorage.getItem("userRole");
	switch (role) {
		case "admin":
			userRole = "administrator";
			break;
		case "editor":
			userRole = "editor";
			break;
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
					>
						<MenuIcon />
					</IconButton>
					<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
						Dashboard
					</Typography>
                    {sessionStorage.getItem("userName") ?
						<>
							<Typography component="h1" variant="h6" color="inherit" noWrap align="right" className={classes.user}>
								{sessionStorage.getItem("userName") ? sessionStorage.getItem("userName") + ", " + userRole : ""}
							</Typography>

							<Divider orientation="vertical" flexItem className={classes.divider} />
							
							<IconButton onClick={logout} disableFocusRipple={true} disableRipple={true} className={classes.logoutButton}>
								<Typography component="h1" variant="h6" color="inherit" noWrap align="right" className={classes.logout}>
									Logout
								</Typography>
							</IconButton>
						</>
					: null}
				</Toolbar>
			</AppBar>
			<Drawer
				variant="temporary"
				classes={{
					paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
				}}
				anchor={theme.direction === "rtl" ? "right" : "left"}
				open={open}
				ModalProps={{ keepMounted: true, onBackdropClick: handleDrawerClose }}
			>
				<div className={classes.toolbarIcon}>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>

				<Divider />

				<List>
					<AuthProvider>
						<Navigation />
					</AuthProvider>
				</List>

				<Grid container style={{ position: "absolute", bottom: 0, padding: '12px', width: drawerWidth, }}>
					<Typography style={{ fontSize: "10px", textAlign: "justify", width: drawerWidth, }}>
						Each HEJDE project associate is responsible for providing complete and accurate data on the particular institutions and study programs for which they are in charge, as well as for the high quality of data interpretation. The HEJDE project team will continuously monitor and verify the accuracy and completeness of data and resolve issues prior to displaying data on the map. However, if you notice any error, please contact the project leader as soon as possible.
					</Typography>

                    <br />

					<Typography style={{ fontSize: "10px", textAlign: "justify", width: drawerWidth, }} noWrap={false} >
						HEJDE project uses an open access map server, which is why deviations from the actual location are possible. Therefore, for the exact location of the entry with the questionable location it is recommended to use the specified address of the institution.
					</Typography>
				</Grid>
			</Drawer>
		</div>
	);
};

export default Header;
