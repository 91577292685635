import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { useSnackBarStore } from "../../Stores/Stores";
import { Alert, AlertProps } from "@material-ui/core";

interface SnackBar {
	message?: string;
	open?: boolean;
	type: AlertProps["severity"];
}
export const SnackBar = ({ type, message, open }: SnackBar) => {
	const snackbarStore = useSnackBarStore();
	return (
		<Snackbar open={open} autoHideDuration={2000} onClose={() => snackbarStore?.hideSnackbar()}>
			<Alert severity={type}>{message}</Alert>
		</Snackbar>
	);
};
export default SnackBar;
