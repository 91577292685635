import React from "react";

import { Redirect } from "react-router";

import { Route, Switch } from "react-router-dom";

import {
	createMuiTheme,
	ThemeProvider,
} from "@material-ui/core";

import { observer } from "mobx-react-lite";

import "../../Style/App.css";
import Dashboard from "../Dashboard/Dashboard";
import Collaborators from "../Collaborators/Collaborators";
import Login from "../Login/Login";
import Register from "../Register/Register";
import Reset from "../Login/Reset";
import AuthProvider from "../../Providers/AuthProvider";
import UserProvider from "../../Providers/UserProvider";
import EditRoles from "../Dashboard/ListOfUsers/Edit/EditRoles";
import SnackBarProvider from "../../Providers/SnackbarProvider";
import InstitutionProvider from "../../Providers/InstitutionProvider";
import Institution from "../Dashboard/Institutions/Institution";
import InstitutionFormFragment from "../Dashboard/Institutions/FormFragment/InstitutionFormFragment";
import StreetMapProvider from "../../Providers/StreetMapProvider";
import Map from "../Map/Map";
import EmbedMap from "../Map/EmbedMap";
import InstitutionDetails from "../Map/InstitutionDetails";
import Instructions from "../Instructions/Instructions";
import Profile from "../Profile/Profile";

const theme = createMuiTheme();

const App = observer(() => {
	return (
		<ThemeProvider theme={theme}>
			<Switch>
				<Route exact path="/">
					<InstitutionProvider>
						<Map />
					</InstitutionProvider>
				</Route>
				<Route exact path="/map">
					<EmbedMap />
				</Route>
				<Route exact path="/instructions">
					<InstitutionProvider>
						<Instructions />
					</InstitutionProvider>
				</Route>
				<Route exact path="/institution/:id">
					<AuthProvider>
						<InstitutionProvider>
							<InstitutionDetails />
						</InstitutionProvider>
					</AuthProvider>
				</Route>
				<Route exact path="/dashboard/institution/edit-institution/:id">
					<InstitutionProvider>
						<StreetMapProvider>
							<InstitutionFormFragment />
						</StreetMapProvider>
					</InstitutionProvider>
				</Route>
				<Route exact path="/login">
					<AuthProvider>
						<Login />
					</AuthProvider>
				</Route>
				<Route exact path="/register">
					<AuthProvider>
						<InstitutionProvider>
							<Register />
						</InstitutionProvider>
					</AuthProvider>
				</Route>
				<Route exact path="/profile">
					<AuthProvider>
						<InstitutionProvider>
							<UserProvider>
								<Profile />
							</UserProvider>
						</InstitutionProvider>
					</AuthProvider>
				</Route>
				<SnackBarProvider>
					<Route exact path="/dashboard">
						<InstitutionProvider>
							<UserProvider>
								<Dashboard />
							</UserProvider>
						</InstitutionProvider>
					</Route>
					<Route exact path="/dashboard/edit-roles/:userId">
						<UserProvider>
							<EditRoles />
						</UserProvider>
					</Route>
					<Route exact path="/collaborators">
						<Collaborators />
					</Route>
					<Route exact path="/reset-password">
						<Reset />
					</Route>
					<Route exact path="/dashboard/institution/create-institution">
						<InstitutionProvider>
							<StreetMapProvider>
								<InstitutionFormFragment />
							</StreetMapProvider>
						</InstitutionProvider>
					</Route>
					<Route exact path="/dashboard/institution">
						<AuthProvider>
							<UserProvider>
								<InstitutionProvider>
									<Institution />
								</InstitutionProvider>
							</UserProvider>
						</AuthProvider>
					</Route>
				</SnackBarProvider>
				<Redirect to="/" />
			</Switch>
		</ThemeProvider>
	);
});

export default App;
