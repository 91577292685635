import React from 'react';

import {
    Grid,
    Container,
    CssBaseline,
    Typography,
    Stepper,
    Step,
    StepButton,
    TextField,
    Button,
    Link,
    makeStyles
} from "@material-ui/core";

import { useHistory } from "react-router-dom";

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

function Reset() {
    const classes = useStyles();
    const history = useHistory();

    //  React state
    const [activeStep, setActiveStep] = React.useState(0);
    const [email, setEmail] = React.useState("");
    const [token, setToken] = React.useState("");
    const [password, setPassword] = React.useState("");

    //  Send reset token to user's e-mail address
    const sendToken = () => {
        if (email) {
            axios.post("https://hejde.um.si/api/forgotPassword", { email: `${email}` } ).then((res) => {
                //  Advance to step 2
                setActiveStep(1);
            }).catch((e) => console.log(e));
        }
    }

    //  Reset user's password
    const resetPassword = () => {
        if (token && password) {
            axios.post("https://hejde.um.si/api/resetPassword", { token: `${token}`, password: `${password}` } ).then((res) => {
                history.push("/login");
            }).catch((e) => console.log(e));
        }
    }

    return (
        <Container component="main" maxWidth="xs">
			<CssBaseline />

			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					Reset password
				</Typography>

                <Stepper alternativeLabel nonLinear activeStep={activeStep} style={{ marginTop: "24px", marginBottom: "24px", }}>
                    <Step key="token">
                        <StepButton
                            onClick={() => setActiveStep(0)}
                        >
                            Receive token
                        </StepButton>
                    </Step>
                    <Step key="reset">
                        <StepButton
                            onClick={() => setActiveStep(1)}
                        >
                            Reset password
                        </StepButton>
                    </Step>
                </Stepper>

                {activeStep == 0 ?
                    <Grid container styles={{ marginTop: "24px", }} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="hejde-email"
                                name="hejde-email"
                                label="E-mail"
                                fullWidth
                                value={email}
                                onChange={(e) => { setEmail(e.target.value); }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Link href="/login" variant="body2">
                                <Button type="button" variant="outlined" color="primary">
                                    Login
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="button" variant="contained" color="primary" onClick={() => sendToken()} style={{ float: "right", }}>
                                Send token
                            </Button>
                        </Grid>
                    </Grid>
                : 
                    <Grid container styles={{ marginTop: "24px", }} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="hejde-token"
                                name="hejde-token"
                                label="Token"
                                autoComplete="new-password"
                                fullWidth
                                value={token}
                                onChange={(e) => { setToken(e.target.value); }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="hejde-password"
                                name="hejde-password"
                                label="New password"
                                type="password"
                                autoComplete="new-password"
                                fullWidth
                                value={password}
                                onChange={(e) => { setPassword(e.target.value); }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Link href="/login" variant="body2">
                                <Button type="button" variant="outlined" color="primary">
                                    Login
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="button" variant="contained" color="primary" onClick={() => resetPassword()} style={{ float: "right", }}>
                                Reset password
                            </Button>
                        </Grid>
                    </Grid>
                }
			</div>
		</Container>
    );
}

export default Reset;