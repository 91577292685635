import { useLocalObservable } from "mobx-react-lite";
import React from "react";
import PropTypes from "prop-types";
import AuthStore, { AuthStoreContext } from "../Stores/AuthStore";
interface IAuthProvider {}

const AuthProvider: React.FC<IAuthProvider> = (props) => {
	const localStore = useLocalObservable(() => new AuthStore());
	return <AuthStoreContext.Provider value={localStore}>{props.children}</AuthStoreContext.Provider>;
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
export default AuthProvider;
