import { action, autorun, makeObservable, observable, runInAction } from "mobx";
import { element } from "prop-types";
import { createContext } from "react";
import { IInstitution } from "../Models/Interfaces/IInstitutions";
import { IOpenStreetMap } from "../Models/Interfaces/IOpenStreetMap";
import { InstitutionService } from "../Services/InstitutionService";
export default class InstitutionStore {
    institution!: IInstitution
    institutions = [] as IInstitution[]

    constructor(){
        makeObservable(this, {
            institution: observable,
            institutions:observable,
            getInstitutions:action,
            getCodeList:action,
            setInstitution:action,
            setOpenStreetMap:action,
            createInstitution: action,
            getNameOfInsitutiton: action,
            getAreaName:action,
        })
    }

    async getInstitutions() {
        const response = await InstitutionService.getInstitutions();
        if (response.ok) {
            const institutionData = await response.json().then((val)=>{return val});
            this.institutions= institutionData.data as IInstitution[];
            return response.status;
        } else {
            return response.status;
        }
    }

    async getCodeList() {
        const response = await InstitutionService.getCodeList();
        if (response.ok) {
            const institutionListData = await response.json().then((val)=>{return val});
            runInAction(() => {
                this.institution = institutionListData.data as IInstitution;
            });
            console.log(institutionListData.data);
            console.log(this.institution?.academicArea?.map((e)=>{return e.academicAreaName}));
            return response.status;
        } else {
            return response.status;
        }
    }

    setInstitution(institution:IInstitution) {
        this.institution=institution;
        console.log(this.institution?.nameOfTheInstitution);
    }

    setOpenStreetMap(osm_type?:string, place_id?:number, osm_id?:number, lat?:string,lon?:string, display_name?:string) {
        this.institution!.osm_id=osm_id;
        this.institution!.osm_type=osm_type;
        this.institution!.place_id=place_id;
        this.institution!.lat=lat;
        this.institution!.lat=lat;
        this.institution!.display_name=display_name;  
    }

    async createInstitution(institution: IInstitution) {
		try {
			const response = await InstitutionService.createInstitution(institution);
			if (response.status === "OK") {
				return response.status;
			} else {
				return response;
			}
		} catch (error) {
			throw error;
		}
	}

    async updateInstitution(institution: IInstitution) {
		try {
			const response = await InstitutionService.updateInstitution(institution);
			if (response.status === "OK") {
                return response.status;
			} else {
				return response;
			}
		} catch (error) {
			throw error;
		}
	}

    async removeInstitution(institutionId: string) {
		try {
			const response = await InstitutionService.removeInstitution(institutionId);
            return response;
		} catch (error) {
			throw error;
		}
    }

    getNameOfInsitutiton(){
        return this.institution?.nameOfTheInstitution
    }

    getAreaName(id:number){
       return this.institution?.academicArea?.map((e)=>{if(e.academicAreaId==id)return e.academicAreaName})
    }
}
export const InstitutionStoreContext = createContext<InstitutionStore | null>(null);
 