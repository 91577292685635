import { EApi } from "../Models/Enums/EApi";
import { IUser } from "../Models/Interfaces/IUser";
import { generateAPIoptions } from "./OptionsHelpser";
import { EToken } from "../Models/Enums/EToken";
import { ILogin } from "../Models/Interfaces/ILogin";

export class AuthService {

    static registerUser = async (user: IUser) =>{
        try {
            const options = generateAPIoptions(EApi.POST, user)
            const response = await fetch (EApi.URL+"/register", options)
            const registerData = await response.json().then((val)=>{return val});
            return registerData;
        }
        catch(error) {
            throw new Error(error)
        }

    }

    static async loginUser (user: ILogin){
        try{
            const options = generateAPIoptions(EApi.POST, user)
            const response = await fetch (EApi.URL+"/login", options)
            if(response.ok){
                const userData = await response.json().then((val)=>{return val});

                this.setToken(userData.data.token)
                this.setUserId(userData.data.userId)
                this.setRole(userData.data.roleName);
                this.setName(userData.data.firstName + " " + userData.data.lastName);

                //returns user data object (status, data{})
                return userData;
            }
            else{
                //returns error object from the server
                return response.json();
            }
        }
        catch(error){
            throw error
        }
    }

    static async logout (){
        const options = generateAPIoptions(EApi.POST,null)
        const response = await fetch (EApi.URL+"/logout", options)
        if(response.status===200) sessionStorage.clear()
        return response.status;
    }
    
    static getToken(){
        const session = sessionStorage.getItem(EToken.TOKEN)
        return session as string;
    }

    static setToken(token:string){
        sessionStorage.setItem("token", token);
    }

    static getUserId(){
        const id = sessionStorage.getItem("userId")
        return id as string;
    }

    static setUserId(userId:string){
        sessionStorage.setItem("userId", userId);
    }

    static setRole(role: string) {
        sessionStorage.setItem("userRole", role);
    }

    static setName(name: string) {
        sessionStorage.setItem("userName", name);
    }
}
