import React from "react";

import {
    makeStyles,
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    Grid,
    Typography,
    MenuItem,
    Button,
    TextField,
    Autocomplete,
    IconButton,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';

import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
} from "react-leaflet";

import "../../Style/index.css";
import Navigation from "../Navigation/Navigation";
import AuthProvider from "../../Providers/AuthProvider";
import { useInstitutionStore } from "../../Stores/Stores";

import HoverCard from "./HoverCard";

import axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}));

function Map() {
    const classes = useStyles();

    const institutionStore = useInstitutionStore();
    //	institutionStore initialization
	React.useEffect(() => {
		const getCodeList = async () => {
			await institutionStore?.getCodeList();
		};
		getCodeList();
	}, [institutionStore]);

    //  React state
    const [initial, setInitial] = React.useState(false);
    const [filterModal, setFilterModal] = React.useState(false);
    const [markers, setMarkers] = React.useState([]);
    const [allMarkers, setAllMarkers] = React.useState([]);
    const [institutions, setInstitutions] = React.useState([]);

    //  React state for filters
    const [studyProgrammeType, setStudyProgrammeType] = React.useState("");
    const [higherEducationInstitutionType, setHigherEducationInstitutionType] = React.useState(null);
    const [typeOfStudyProgramId, setTypeOfStudyProgramId] = React.useState(null);
    const [durationInYears, setDurationInYears] = React.useState(null);
    const [qualificationTitle, setQualificationTitle] = React.useState("");

    //  Map position and attribution
    const position = [45.849065, 14.486211];
    const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    const URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    //  Autocomplete options
    let studyProgrammeTypeOptions = [];
    institutionStore?.institution?.studyProgrammeType?.map((institution) => {
        studyProgrammeTypeOptions.push(institution.studyProgrammeTypeName);
    })

    //  Render marker
    const marker = (marker) => {
        return (
            <Marker
                radius={6}
                weight={2}
                stroke={true}
                fillOpacity="1"
                zIndexOffset={10}
                key={marker.institutionId}
                position={[marker.lat, marker.lon]}
                onMouseOver={(e) => {
                    e.target.openPopup();
                }}
            >
                <Popup>
                    <HoverCard 
                        name={marker.nameOfTheInstitution} 
                        institutionId={marker.institutionId} 
                        isDetails={false} 
                    />
                </Popup>
            </Marker>
        );
    }

    //  GET /institution
    axios.get("https://hejde.um.si/api/institution")
        .then(res => {
            //  Check if markers are already rendered
            //  Check if institutions exist in database
            if (!initial && res.data.data.length > 0) {
                let institutionMarkers = [];

                for (let i = 0; i < res.data.data.length; i++)
                    //  Check if data contains coordinates
                    if (res.data.data[i].lat && res.data.data[i].lon && res.data.data[i].hidden == 0)
                        //  Render marker for institution
                        institutionMarkers.push(marker(res.data.data[i]));

                setMarkers(institutionMarkers);
                setAllMarkers(institutionMarkers);
                setInstitutions(res.data.data);
                setInitial(true);
            }
        })
        .catch(error => {
            console.log(error);
        });

    const handleFilterModal = () => {
        setFilterModal(true);
    }

    const handleFilterModalClose = () => {
        setFilterModal(false);
    }

    //  Filtering on button click
    const filter = () => {
        let filtered = [...institutions];
        
        //  Filtering institutions by study programme type
        if (studyProgrammeType && studyProgrammeType != "") {
            filtered = filtered.filter(element => {
                for (let i = 0; i < element.studyPrograms.length; i++) {
                    if (element.hidden == "0")
                        if (element.studyPrograms[i].studyProgrammeType.toLowerCase().includes(studyProgrammeType.toLowerCase()))
                            return true;
                }
            });
        }

        //  Filtering institutions (higherEducationInstitutionType)
        if (higherEducationInstitutionType)
            filtered = filtered.filter(element => {
                if (element.hidden == "0")
                    return element.higherEducationInstitutionTypeId === higherEducationInstitutionType;
            });


        //  Filtering institutions by study programme learning type
        if (typeOfStudyProgramId)
            filtered = filtered.filter(element => {
                if (element.hidden == "0")
                    for (let i = 0; i < element.studyPrograms.length; i++)
                        if (element.studyPrograms[i].typeOfStudyProgramId == typeOfStudyProgramId)
                            return true;
            });

        //  Filtering institutions by study programme duration
        if (durationInYears)
            filtered = filtered.filter(element => {
                if (element.hidden == "0")
                    for (let i = 0; i < element.studyPrograms.length; i++)
                        if (element.studyPrograms[i].yearsOfTheStudyProgramDuration == durationInYears)
                            return true;
            });

        //  Filtering institutions by study programme qualification title
        if (qualificationTitle && qualificationTitle != "")
            filtered = filtered.filter(element => {
                if (element.hidden == "0")
                    for (let i = 0; i < element.studyPrograms.length; i++)
                        if (element.studyPrograms[i].titleOfQualification.toLowerCase().includes(qualificationTitle.toLowerCase()))
                            return true;
            });

        //  Render filtered instittuions
        let institutionMarkers = [];
        for (let i = 0; i < filtered.length; i++)
            //  Check if data contains coordinates
            if (filtered[i].lat && filtered[i].lon)
                //  Render marker for institution
                institutionMarkers.push(marker(filtered[i]));
        
        //  If filters are selected, display matching results
        if (studyProgrammeType || higherEducationInstitutionType || typeOfStudyProgramId || durationInYears || qualificationTitle)
            setMarkers(institutionMarkers);

        //  If none of the filters are selected, revert to default display
        if (!studyProgrammeType && !higherEducationInstitutionType && !typeOfStudyProgramId && !durationInYears && !qualificationTitle)
            setMarkers(allMarkers);

        handleFilterModalClose();
    };
    
    //  Reset filter settings to default and display all institutions
    const resetFilter = () => {
        //  Reset filter options
        setStudyProgrammeType("");
        setHigherEducationInstitutionType(null);
        setTypeOfStudyProgramId(null);
        setDurationInYears(null);
        setQualificationTitle("");

        //  Reset markers to default
        setMarkers(allMarkers);
    }

    return (
        <div className="main-map-container">
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{ paper: classes.drawerPaper, }}
                anchor="left"
            >
                <Divider />

                <List>
                    <AuthProvider>
                        <Navigation />
                    </AuthProvider>
                </List>

                <Divider />

                <List>
                    <ListItem button onClick={() => handleFilterModal()}>
                        <ListItemIcon>
                            <FilterListIcon />
                        </ListItemIcon>
                        <ListItemText primary="Filter" />
                    </ListItem>
                </List>

				<Grid container style={{ position: "absolute", bottom: 0, padding: '12px', width: drawerWidth, }}>
					<Typography style={{ fontSize: "10px", textAlign: "justify", width: drawerWidth, }}>
						Each HEJDE project associate is responsible for providing complete and accurate data on the particular institutions and study programs for which they are in charge, as well as for the high quality of data interpretation. The HEJDE project team will continuously monitor and verify the accuracy and completeness of data and resolve issues prior to displaying data on the map. However, if you notice any error, please contact the project leader as soon as possible.
					</Typography>

                    <br />

					<Typography style={{ fontSize: "10px", textAlign: "justify", width: drawerWidth, }} noWrap={false} >
						HEJDE project uses an open access map server, which is why deviations from the actual location are possible. Therefore, for the exact location of the entry with the questionable location it is recommended to use the specified address of the institution.
					</Typography>
				</Grid>
            </Drawer>

            <MapContainer
                center={position}
                minZoom={5} 
                zoom={5}
                maxZoom={10}
                scrollWheelZoom={true}
            >
                <TileLayer attribution={attribution} url={URL} />
                {markers}
            </MapContainer>

            <Dialog
                open={filterModal}
                onClose={() => handleFilterModalClose()}
                className="filter-dialog"
            >
                <Grid container>
                    <Grid item xs={11}>
                        <DialogTitle style={{ paddingLeft: "24px", paddingRight: "24px", paddingTop: "24px", }}>
                            Filter
                        </DialogTitle>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton 
                            disableRipple={true}
                            disableFocusRipple={true}
                            onClick={() => handleFilterModalClose()}
                            style={{ float: "right", paddingLeft: "24px", paddingRight: "24px", paddingTop: "24px", backgroundColor: "transparent", }} 
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <DialogContent style={{ paddingLeft: "24px", paddingRight: "24px", paddingBottom: "24px", }}>
                    <DialogActions style={{ padding: "0px", }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography>
                                    Study programme type
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                            <Autocomplete
                                freeSolo
                                value={studyProgrammeType}
                                defaultValue={studyProgrammeType}
                                onChange={(event, value) => { setStudyProgrammeType(value); }}
                                options={studyProgrammeTypeOptions}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params}
                                        id="studyProgrammeType"
                                        name="studyProgrammeType"
                                        label="Study programme type"
                                        fullWidth
                                        value={studyProgrammeType}
                                        onChange={(e) => { setStudyProgrammeType(e.target.value); }}
                                    />
                                )}
                            />
                            </Grid>

                            <Grid item xs={6}>
                                <Typography>
                                    Higher-education institution type
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Select 
                                    onChange={(e) => { setHigherEducationInstitutionType(e.target.value); }}
                                    value={higherEducationInstitutionType ? higherEducationInstitutionType : ""}
                                    name="fk_higherEducationInstitutionType"
                                    style={{ width: "100%" }}
                                >
                                    <MenuItem key="_higherEducationInstitutionType" value={null}>
                                        <span style={{ color: "transparent" }}>_</span>
                                    </MenuItem>
                                    {institutionStore?.institution?.higherEducationInstitutionType?.map((institution) => (
                                        <MenuItem key={institution.higherEducationInstitutionTypeId} value={institution.higherEducationInstitutionTypeId}>
                                            {institution.higherEducationInstitutionTypeName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography>
                                    Study programme learning type
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    onChange={(e) => { setTypeOfStudyProgramId(e.target.value); }}
                                    value={typeOfStudyProgramId} 
                                    name="typeOfStudyProgramName"
                                    style={{ width: "100%" }}
                                >
                                    <MenuItem key="_typeOfStudyProgramName" value={null}>
                                        <span style={{ color: "transparent" }}>_</span>
                                    </MenuItem>
                                    {institutionStore?.institution?.typeOfStudyProgram?.map((institution) => (
                                        <MenuItem key={institution.typeOfStudyProgramId} value={institution.typeOfStudyProgramId}>
                                            {institution.typeOfStudyProgramName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography>
                                    Study programme duration (in years)
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Select 
                                    onChange={(e) => { setDurationInYears(e.target.value); }}
                                    value={durationInYears}
                                    name="yearsOfTheStudyProgramDuration"
                                    style={{ width: "100%" }}
                                >
                                    <MenuItem key="_typeOfStudyProgramName" value={null}>
                                        <span style={{ color: "transparent" }}>_</span>
                                    </MenuItem>                                    
                                    <MenuItem key="1" value="1">1</MenuItem>
                                    <MenuItem key="2" value="2">2</MenuItem>
                                    <MenuItem key="3" value="3">3</MenuItem>
                                    <MenuItem key="4" value="4">4</MenuItem>
                                    <MenuItem key="5" value="5">5</MenuItem>
                                    <MenuItem key="6" value="6">6</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography>
                                    Qualification title
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="titleOfQualification"
                                    name="titleOfQualification"
                                    label="Qualification title"
                                    fullWidth
                                    value={qualificationTitle}
                                    onChange={(e) => { setQualificationTitle(e.target.value); }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button type="button" variant="contained" color="primary" onClick={() => filter()} style={{ float: "right", marginLeft: "12px", }}>
                                    Filter
                                </Button>

                                <Button type="button" variant="outlined" color="primary" onClick={() => resetFilter()} style={{ float: "right", }}>
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Map;