import React from "react";

import {
    Link,
} from "react-router-dom";

import {
    Card,
    CardContent,
    Typography,
    makeStyles,
    Grid,
} from "@material-ui/core";

import SchoolIcon from "@material-ui/icons/School";

const useStyles = makeStyles(theme => ({
    infoCard: {
        maxWidth: "400px",
        float: "right",
        boxShadow: "0px 0px 15px #787878",
        borderRadius: "15px",
    },
    hoverContent: {
        paddingTop: "16px !important",
        paddingBottom: "16px !important",
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
    },
    hoverTitle: {
        fontSize: "12px !important",
        textTransform: "uppercase !important",
    },
    hoverLocation: {
        marginBottom: "0px !important",
        fontWeight: "500 !important",
        fontSize: "24px !important",
    },
    link: {
        textDecoration: "none",
        color: "inherit !important",
    }
}));

function HoverCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.infoCard}>
            <CardContent className={classes.hoverContent}>
                <Typography className={classes.hoverTitle} color="textSecondary">
                    <SchoolIcon fontSize="small" style={{ height: "12px" }} />
                    <span>Institution</span>
                </Typography>
                {!props.isDetails ? 
                    <a href={`https://hejde.um.si/institution/${props.institutionId}`} className={classes.link} target="_blank">
                        <Typography className={classes.hoverLocation} variant="h1">
                            {props.name}
                        </Typography>
                    </a>
                        : 
                    <Typography className={classes.hoverLocation} variant="h1">
                        {props.name}
                    </Typography>
                }
                {!props.isDetails ? 
                    <Grid container>
                        <Grid item xs={12} style={{ marginTop: "10px"}}>
                            <a href={`https://hejde.um.si/institution/${props.institutionId}`} target="_blank">
                                Find out more
                            </a>
                        </Grid>
                    </Grid>                
                : null}
            </CardContent>
        </Card>
    );
}

export default HoverCard;