import { EApi } from "../Models/Enums/EApi";
import { IUser } from "../Models/Interfaces/IUser";
import { generateAPIoptions } from "./OptionsHelpser";

export class UserService {

    static getUsers = async ():Promise<Response> =>{
        try{
            const options = generateAPIoptions(EApi.GET, null)
            const response = await fetch (EApi.URL+"/user", options)
            return response
        }
        catch(error){
            throw new Error(error)
        }

    }

    static changeUserStatus = async (userId:string, roleId:string) =>{
        try{
            const payload = {userId:userId, roleId:roleId}
            const options = generateAPIoptions(EApi.POST, payload)
            const response = await fetch (EApi.URL+"/user/changeStatus", options)
            if(response.ok){
                const roleData = await response.json().then((val)=>{return val});
                return roleData;
            }
            else{
                return response;
            }

        }
        catch(error){
            throw new Error(error)
        }
    }

    static removeUser = async (userId: string) => {
        try {
            const payload = { userId: userId };
            const options = generateAPIoptions(EApi.DELETE, payload);
            const response = await fetch (EApi.URL+"/user", options);
            if (response.ok) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    static setUser(user:IUser){
        sessionStorage.setItem("user", JSON.stringify(user));
    }

    static getSessionUsers(){
        return JSON.parse(localStorage.getItem('user') || '{}');
    }
}