import React from "react";

import { useParams } from "react-router-dom";

import { 
	FormControl, 
    FormControlLabel, 
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Grid, 
    Button, 
    Typography, 
	Snackbar,
	Alert, 
    Checkbox, 
    Modal, 
    Fade, 
    Autocomplete, 
} from "@material-ui/core";
import IconClose from "@material-ui/icons/Close";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Controller, useForm } from "react-hook-form";

import { useInstitutionStore } from "../../../../Stores/Stores";
import { IInstitution } from "../../../../Models/Interfaces/IInstitutions";

const StudyProgram = ({ formValues, index, id, institutionData }: any) => {
    const params: any = useParams();
    const institutionID = params.id;

    const institutionStore = useInstitutionStore();
	const [fetched, setFetched] = React.useState(false);
	const { register, control, handleSubmit, setValue } = useForm<IInstitution>();

	React.useEffect(() => {
		const getCodeList = async () => {
			await institutionStore?.getCodeList();
			setFetched(true);
		};
		getCodeList();
	}, [institutionStore]);

    //  Recovery from localStorage
    let JSON_string: any, JSON_object: any, JSON_string_study_programmes: any;
    if (!institutionID) {
        JSON_string = localStorage.getItem(`studyProgram${index}`);
        JSON_object = JSON.parse(JSON_string);
        JSON_string_study_programmes = localStorage.getItem("studyProgrammes");
    } else {
        JSON_string = sessionStorage.getItem(`studyProgram${index}`);
        JSON_object = JSON.parse(JSON_string);
        JSON_string_study_programmes = sessionStorage.getItem("studyProgrammes");
    }

    let isDisabled = false;
    if (JSON_object)
        if (JSON_object.isFoundationYearDisabled || !JSON_object.isFoundationYearDisabled && !JSON_object.yearOfFoundationOfTheStudyProgram)
            isDisabled = true;

    //  React state
    const [nameOfTheStudyProgram, setNameOfTheStudyProgram] = React.useState(JSON_object ? JSON_object.nameOfTheStudyProgram : "");
	const [studyProgramWebsiteLink, setStudyProgramWebsiteLink] = React.useState(JSON_object ? JSON_object.studyProgramWebsiteLink : "");
	const [yearOfFoundationOfTheStudyProgram, setYearOfFoundationOfStudyProgram] = React.useState(JSON_object ? JSON_object.yearOfFoundationOfTheStudyProgram : "");
	const [studyProgrammeType, setStudyProgrammeType] = React.useState(JSON_object ? JSON_object.studyProgrammeType : "");
    const [typeOfStudyProgramId, setTypeOfStudyProgramId] = React.useState(JSON_object ? JSON_object.fk_typeOfStudyProgram : "");
	const [studyDegreeRegardingEQFNQFId, setStudyDegreeRegardingEQFNQFId] = React.useState(JSON_object ? JSON_object.fk_studyDegreeRegardingEQFNQF : "");
	const [yearsAfterPrimarySecondarySchool, setYearsAfterPrimarySecondarySchool] = React.useState(JSON_object ? JSON_object.yearsAfterPrimarySecondarySchool : "");
	const [yearsOfTheStudyProgramDuration, setYearsOfTheStudyProgramDuration] = React.useState(JSON_object ? JSON_object.yearsOfTheStudyProgramDuration : "");
	const [ECTS, setECTS] = React.useState(JSON_object ? JSON_object.ECTS : "");
    const [scientificDegree, setScientificDegree] = React.useState(JSON_object ? JSON_object.scientificDegree ? JSON_object.scientificDegree : false : false);
    const [doubleDegree, setDoubleDegree] = React.useState(JSON_object ? JSON_object.doubleDegree ? JSON_object.doubleDegree : false : false);
	const [titleOfDegree, setTitleOfDegree] = React.useState(JSON_object ? JSON_object.titleOfDegree : "");
    const [degreeLevel, setDegreeLevel] = React.useState(JSON_object ? JSON_object.degreeLevel : "");
	const [titleOfQualification, setTitleOfQualification] = React.useState(JSON_object ? JSON_object.titleOfQualification : "");
    const [studyProgrammeLevels, setStudyProgrammeLevels] = React.useState(JSON_object ? JSON_object.studyProgrammeLevels : "");
	const [annualStudentEnrollmentQuotaId, setAnnualStudentEnrollmentQuotaId] = React.useState<string[]>(JSON_object ? JSON_object.fk_annualStudentEnrollmentQuota : []);
    const [academicCredentialType, setAcademicCredentialType] = React.useState(JSON_object ? JSON_object.fk_academicCredentialType : "");

    const [isChanged, setIsChanged] = React.useState(false);
    const [yearModal, setYearModal] = React.useState(false);

    const [isFoundationYearDisabled, setIsFoundationYearDisabled] = React.useState(JSON_object ? JSON_object.isFoundationYearDisabled : false);
    const [modal, setModal] = React.useState(false);

    const [studyProgrammes, setStudyProgrammes] = React.useState(JSON_string_study_programmes);

    //  Recover data from localStorage
    if (JSON_object)
        if (nameOfTheStudyProgram !== JSON_object.nameOfTheStudyProgram && studyProgrammes !== JSON_string_study_programmes && !institutionID) {
            setNameOfTheStudyProgram(JSON_object.nameOfTheStudyProgram ? JSON_object.nameOfTheStudyProgram : "");
            setStudyProgramWebsiteLink(JSON_object.studyProgramWebsiteLink ? JSON_object.studyProgramWebsiteLink : "");
            setYearOfFoundationOfStudyProgram(JSON_object.yearOfFoundationOfTheStudyProgram ? JSON_object.yearOfFoundationOfTheStudyProgram : "");
            setStudyProgrammeType(JSON_object.studyProgrammeType ? JSON_object.studyProgrammeType : "");
            setTypeOfStudyProgramId(JSON_object.fk_typeOfStudyProgram ? JSON_object.fk_typeOfStudyProgram : "");
            setStudyDegreeRegardingEQFNQFId(JSON_object.fk_studyDegreeRegardingEQFNQF ? JSON_object.fk_studyDegreeRegardingEQFNQF : "");
            setYearsAfterPrimarySecondarySchool(JSON_object.yearsAfterPrimarySecondarySchool ? JSON_object.yearsAfterPrimarySecondarySchool : "");
            setYearsOfTheStudyProgramDuration(JSON_object.yearsOfTheStudyProgramDuration ? JSON_object.yearsOfTheStudyProgramDuration : "");
            setECTS(JSON_object.ECTS ? JSON_object.ECTS : "");
            setDoubleDegree(JSON_object.doubleDegree ? true : false);
            setScientificDegree(JSON_object.scientificDegree ? true : false);
            setTitleOfDegree(JSON_object.titleOfDegree ? JSON_object.titleOfDegree : "");
            setDegreeLevel(JSON_object.degreeLevel ? JSON_object.degreeLevel : "");
            setTitleOfQualification(JSON_object.titleOfQualification ? JSON_object.titleOfQualification : "");
            setStudyProgrammeLevels(JSON_object.studyProgrammeLevels ? JSON_object.studyProgrammeLevels : "");
            setAnnualStudentEnrollmentQuotaId(JSON_object.fk_annualStudentEnrollmentQuota ? JSON_object.fk_annualStudentEnrollmentQuota : []);
            setAcademicCredentialType(JSON_object.fk_academicCredentialType ? JSON_object.fk_academicCredentialType : "");

            setIsFoundationYearDisabled(JSON_object.isFoundationYearDisabled ? JSON_object.isFoundationYearDisabled : false);
            setStudyProgrammes(JSON_string_study_programmes);
        }

    React.useEffect(() => {
        if (!institutionID) {
            if (nameOfTheStudyProgram && studyProgramWebsiteLink && (yearOfFoundationOfTheStudyProgram || isFoundationYearDisabled) && studyProgrammeType && typeOfStudyProgramId && studyDegreeRegardingEQFNQFId && yearsAfterPrimarySecondarySchool && yearsOfTheStudyProgramDuration && ECTS && titleOfDegree && degreeLevel && titleOfQualification && studyProgrammeLevels && annualStudentEnrollmentQuotaId.length !== 0 && academicCredentialType) {
                const studyProgram = {
                    nameOfTheStudyProgram: nameOfTheStudyProgram,
                    studyProgramWebsiteLink: studyProgramWebsiteLink,
                    yearOfFoundationOfTheStudyProgram: isFoundationYearDisabled ? null : yearOfFoundationOfTheStudyProgram,
                    studyProgrammeType: studyProgrammeType,
                    ECTS: ECTS,
                    doubleDegree: doubleDegree,
                    scientificDegree: scientificDegree,
                    titleOfDegree: titleOfDegree,
                    degreeLevel: degreeLevel,
                    titleOfQualification: titleOfQualification,
                    studyProgrammeLevels: studyProgrammeLevels,
                    yearsAfterPrimarySecondarySchool: yearsAfterPrimarySecondarySchool,
                    yearsOfTheStudyProgramDuration: yearsOfTheStudyProgramDuration,
                    fk_annualStudentEnrollmentQuota: annualStudentEnrollmentQuotaId,
                    fk_studyDegreeRegardingEQFNQF: studyDegreeRegardingEQFNQFId,
                    fk_typeOfStudyProgram: typeOfStudyProgramId,
                    fk_academicCredentialType: academicCredentialType,
                    isFilled: true,
                    index: index,
                    isFoundationYearDisabled: isFoundationYearDisabled,
                };
        
                localStorage.setItem(`studyProgram${index}`, JSON.stringify(studyProgram));
            } else {
                const studyProgram = {
                    nameOfTheStudyProgram: nameOfTheStudyProgram,
                    studyProgramWebsiteLink: studyProgramWebsiteLink,
                    yearOfFoundationOfTheStudyProgram: isFoundationYearDisabled ? null : yearOfFoundationOfTheStudyProgram,
                    studyProgrammeType: studyProgrammeType,
                    ECTS: ECTS,
                    doubleDegree: doubleDegree,
                    scientificDegree: scientificDegree,
                    titleOfDegree: titleOfDegree,
                    degreeLevel: degreeLevel,
                    titleOfQualification: titleOfQualification,
                    studyProgrammeLevels: studyProgrammeLevels,
                    yearsAfterPrimarySecondarySchool: yearsAfterPrimarySecondarySchool,
                    yearsOfTheStudyProgramDuration: yearsOfTheStudyProgramDuration,
                    fk_annualStudentEnrollmentQuota: annualStudentEnrollmentQuotaId,
                    fk_studyDegreeRegardingEQFNQF: studyDegreeRegardingEQFNQFId,
                    fk_typeOfStudyProgram: typeOfStudyProgramId,
                    fk_academicCredentialType: academicCredentialType,
                    isFilled: false,
                    index: index,
                    isFoundationYearDisabled: isFoundationYearDisabled,
                };
    
                localStorage.setItem(`studyProgram${index}`, JSON.stringify(studyProgram));
            }
        } else {
            if (nameOfTheStudyProgram && studyProgramWebsiteLink && (yearOfFoundationOfTheStudyProgram || isFoundationYearDisabled) && studyProgrammeType && typeOfStudyProgramId && studyDegreeRegardingEQFNQFId && yearsAfterPrimarySecondarySchool && yearsOfTheStudyProgramDuration && ECTS && titleOfDegree && degreeLevel && titleOfQualification && studyProgrammeLevels && annualStudentEnrollmentQuotaId.length !== 0 && academicCredentialType) {
                const studyProgram = {
                    nameOfTheStudyProgram: nameOfTheStudyProgram,
                    studyProgramWebsiteLink: studyProgramWebsiteLink,
                    yearOfFoundationOfTheStudyProgram: isFoundationYearDisabled ? null : yearOfFoundationOfTheStudyProgram,
                    studyProgrammeType: studyProgrammeType,
                    ECTS: ECTS,
                    doubleDegree: doubleDegree,
                    scientificDegree: scientificDegree,
                    titleOfDegree: titleOfDegree,
                    degreeLevel: degreeLevel,
                    titleOfQualification: titleOfQualification,
                    studyProgrammeLevels: studyProgrammeLevels,
                    yearsAfterPrimarySecondarySchool: yearsAfterPrimarySecondarySchool,
                    yearsOfTheStudyProgramDuration: yearsOfTheStudyProgramDuration,
                    fk_annualStudentEnrollmentQuota: annualStudentEnrollmentQuotaId,
                    fk_studyDegreeRegardingEQFNQF: studyDegreeRegardingEQFNQFId,
                    fk_typeOfStudyProgram: typeOfStudyProgramId,
                    fk_academicCredentialType: academicCredentialType,
                    isFilled: true,
                    index: index,
                    isFoundationYearDisabled: isFoundationYearDisabled,
                };
        
                sessionStorage.setItem(`studyProgram${index}`, JSON.stringify(studyProgram));
            } else {
                const studyProgram = {
                    nameOfTheStudyProgram: nameOfTheStudyProgram,
                    studyProgramWebsiteLink: studyProgramWebsiteLink,
                    yearOfFoundationOfTheStudyProgram: isFoundationYearDisabled ? null : yearOfFoundationOfTheStudyProgram,
                    studyProgrammeType: studyProgrammeType,
                    ECTS: ECTS,
                    doubleDegree: doubleDegree,
                    scientificDegree: scientificDegree,
                    titleOfDegree: titleOfDegree,
                    degreeLevel: degreeLevel,
                    titleOfQualification: titleOfQualification,
                    studyProgrammeLevels: studyProgrammeLevels,
                    yearsAfterPrimarySecondarySchool: yearsAfterPrimarySecondarySchool,
                    yearsOfTheStudyProgramDuration: yearsOfTheStudyProgramDuration,
                    fk_annualStudentEnrollmentQuota: annualStudentEnrollmentQuotaId,
                    fk_studyDegreeRegardingEQFNQF: studyDegreeRegardingEQFNQFId,
                    fk_typeOfStudyProgram: typeOfStudyProgramId,
                    fk_academicCredentialType: academicCredentialType,
                    isFilled: false,
                    index: index,
                    isFoundationYearDisabled: isFoundationYearDisabled,
                };
    
                sessionStorage.setItem(`studyProgram${index}`, JSON.stringify(studyProgram));
            }
        }
    }, [nameOfTheStudyProgram, studyProgramWebsiteLink, yearOfFoundationOfTheStudyProgram, studyProgrammeType, typeOfStudyProgramId, studyDegreeRegardingEQFNQFId, yearsAfterPrimarySecondarySchool, yearsOfTheStudyProgramDuration, ECTS, titleOfDegree, degreeLevel, titleOfQualification, annualStudentEnrollmentQuotaId, isFoundationYearDisabled, doubleDegree, scientificDegree, academicCredentialType, studyProgrammeLevels]);

    let degreeLevelOptions: any = [];
    institutionStore?.institution?.degreeLevel?.map((institution) => {
        degreeLevelOptions.push(institution.degreeLevelName);
    })

    let studyProgrammeTypeOptions: any = [];
    institutionStore?.institution?.studyProgrammeType?.map((institution) => {
        studyProgrammeTypeOptions.push(institution.studyProgrammeTypeName);
    })

    let studyProgrammeLevelsOptions: any = [];
    institutionStore?.institution?.studyProgrammeLevels?.map((institution) => {
        studyProgrammeLevelsOptions.push(institution.studyProgrammeLevelsName);
    })

    const handleTypeOfStudentEnrollment = (e: React.ChangeEvent<{ value: unknown }>) => {
		setAnnualStudentEnrollmentQuotaId(e.target.value as string[]);
	}

	const handleYearOfFoundationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		const val = e.target.value[value.length - 1];

		if (val === "0" || val === "1" || val === "2" || val === "3" || val === "4" || val === "5" || val === "6" || val === "7" || val === "8" || val === "9" || value === "") {
			setYearOfFoundationOfStudyProgram(e.target.value);
		} else {
			setYearModal(true);
		}
	}

    const handleECTSChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const val = e.target.value[value.length - 1];

		if (val === "0" || val === "1" || val === "2" || val === "3" || val === "4" || val === "5" || val === "6" || val === "7" || val === "8" || val === "9" || value === "") {
			setECTS(e.target.value);
		} else {
			setYearModal(true);
		}
    }

    const handleCheckbox = () => {
		setIsFoundationYearDisabled(!isFoundationYearDisabled);
		setModal(false);
	}

    const capitalize = (input: string) => {
        let separateWords = input.toLowerCase().split(" ");
        for (let i = 0; i < separateWords.length; i++) {
            separateWords[i] = separateWords[i].charAt(0).toLowerCase() + separateWords[i].substring(1);
        }

        return separateWords.join(" ");
    }

	const handleModal = () => {
		if (!isFoundationYearDisabled) {
			setModal(true);
		} else {
			setIsFoundationYearDisabled(!isFoundationYearDisabled);
		}
	}
	
	const handleModalClose = () => {
		setModal(false);
	}

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        inputRef={register({ required: true })}
                        required
                        id={`nameOfTheStudyProgram${index}`}
                        name="nameOfTheStudyProgram"
                        label="Study programme name"
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setNameOfTheStudyProgram(e.target.value); setIsChanged(true); }}
                        value={nameOfTheStudyProgram}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        inputRef={register({ required: true })}
                        required
                        id={`studyProgramWebsiteLink${index}`}
                        name="studyProgramWebsiteLink"
                        label="Study programme website link"
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStudyProgramWebsiteLink(e.target.value); setIsChanged(true); }}
                        value={studyProgramWebsiteLink}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth style={{ marginTop: "5px", }}>
                        <Controller
                            type="checkbox"
                            variant="outlined"
                            control={control}
                            refs={register}
                            name="isFoundationYearDisabled"
                            defaultValue={isFoundationYearDisabled}
                            render={({ onChange, onBlur, value }) => {
                                return (
                                    <FormControlLabel
                                        control={<Checkbox checked={isFoundationYearDisabled} onChange={() => { handleModal(); }} name="isFoundationYearDisabled" color="primary" />}
                                        label="Study programme foundation year not found/unknown"
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        inputRef={register({ required: !isFoundationYearDisabled })}
                        required
                        id={`yearOfFoundationOfTheStudyProgram${index}`}
                        name="yearOfFoundationOfTheStudyProgram"
                        label="Study programme foundation year"
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleYearOfFoundationChange(e); setIsChanged(true); }}
                        value={yearOfFoundationOfTheStudyProgram}
                        disabled={isFoundationYearDisabled}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                        freeSolo
                        defaultValue={studyProgrammeType}
                        onChange={(event: any, value: string | null) => { setStudyProgrammeType(value); setIsChanged(true); }}
                        options={studyProgrammeTypeOptions}
                        renderInput={(params) => (
                            <TextField 
                                {...params}
                                inputRef={register({ required: true })}
                                required
                                id={`studyProgramType${index}`}
                                name="studyProgrammeType"
                                label="Study programme type"
                                fullWidth
                                value={studyProgrammeType}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStudyProgrammeType(e.target.value); setIsChanged(true); }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl required fullWidth>
                        <InputLabel variant="outlined">Study programme learning type</InputLabel>
                        <Controller
                            type="select"
                            variant="outlined"
                            control={control}
                            refs={register({ required: true })}
                            name="fk_typeOfStudyProgram"
                            defaultValue={typeOfStudyProgramId ? typeOfStudyProgramId : ""}
                            render={({ onChange, onBlur, value }) => {
                                return (
                                    <Select
                                        onChange={(e: React.ChangeEvent<{value: unknown}>) => { setTypeOfStudyProgramId(e.target.value as string); setIsChanged(true); }}
                                        onBlur={onBlur} 
                                        value={typeOfStudyProgramId ? typeOfStudyProgramId : ""} 
                                        name="typeOfStudyProgramName"
                                    >
                                        {institutionStore?.institution?.typeOfStudyProgram?.map((institution) => (
                                            <MenuItem key={institution.typeOfStudyProgramId} value={institution.typeOfStudyProgramId}>
                                                {institution.typeOfStudyProgramName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl required fullWidth>
                        <InputLabel variant="outlined">Study programme degree regarding EQF/NQF</InputLabel>
                        <Controller
                            type="select"
                            variant="outlined"
                            refs={register({ required: true })}
                            control={control}
                            name="fk_studyDegreeRegardingEQFNQF"
                            defaultValue={studyDegreeRegardingEQFNQFId ? studyDegreeRegardingEQFNQFId : ""}
                            render={({ onChange, onBlur, value }) => {
                                return (
                                    <Select 
                                        onChange={(e: React.ChangeEvent<{value: unknown}>) => { setStudyDegreeRegardingEQFNQFId(e.target.value as string); setIsChanged(true); }}
                                        onBlur={onBlur} 
                                        value={studyDegreeRegardingEQFNQFId ? studyDegreeRegardingEQFNQFId : ""} 
                                        name="fk_studyDegreeRegardingEQFNQF"
                                    >
                                        {institutionStore?.institution?.studyDegreeRegardingEQFNQF?.map((institution) => (
                                            <MenuItem key={institution.studyDegreeRegardingEQFNQFId} value={institution.studyDegreeRegardingEQFNQFId}>
                                                {institution.studyDegreeRegardingEQFNQFName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl required fullWidth>
                        <InputLabel variant="outlined">How many years of primary and secondary school in total does a student need to enroll in the study programme</InputLabel>
                        <Controller
                            type="select"
                            variant="outlined"
                            control={control}
                            refs={register({ required: true })}
                            name="yearsAfterPrimarySecondarySchool"
                            defaultValue={yearsAfterPrimarySecondarySchool ? yearsAfterPrimarySecondarySchool : ""}
                            render={({ onChange, onBlur, value }) => {
                                return (
                                    <Select 
                                        onChange={(e: React.ChangeEvent<{value: unknown}>) => { setYearsAfterPrimarySecondarySchool(e.target.value as string); setIsChanged(true); }}
                                        onBlur={onBlur}
                                        value={yearsAfterPrimarySecondarySchool ? yearsAfterPrimarySecondarySchool : ""}
                                        name="yearsAfterPrimarySecondarySchool"
                                    >
                                        <MenuItem key="6" value="6">6</MenuItem>
                                        <MenuItem key="7" value="7">7</MenuItem>
                                        <MenuItem key="8" value="8">8</MenuItem>
                                        <MenuItem key="9" value="9">9</MenuItem>
                                        <MenuItem key="10" value="10">10</MenuItem>
                                        <MenuItem key="11" value="11">11</MenuItem>
                                        <MenuItem key="12" value="12">12</MenuItem>
                                        <MenuItem key="13" value="13">13</MenuItem>
                                        <MenuItem key="14" value="14">14</MenuItem>
                                    </Select>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel variant="outlined">Study programme duration (in years)</InputLabel>
                        <Controller
                            type="select"
                            variant="outlined"
                            control={control}
                            refs={register({ required: true })}
                            name="yearsOfTheStudyProgramDuration"
                            defaultValue={yearsOfTheStudyProgramDuration ? yearsOfTheStudyProgramDuration : ""}
                            render={({ onChange, onBlur, value }) => {
                                return (
                                    <Select 
                                        onChange={(e: React.ChangeEvent<{value: unknown}>) => { setYearsOfTheStudyProgramDuration(e.target.value as string); setIsChanged(true); }}
                                        onBlur={onBlur}
                                        value={yearsOfTheStudyProgramDuration ? yearsOfTheStudyProgramDuration : ""}
                                        name="yearsOfTheStudyProgramDuration"
                                    >
                                        <MenuItem key="1" value="1">1</MenuItem>
                                        <MenuItem key="2" value="2">2</MenuItem>
                                        <MenuItem key="3" value="3">3</MenuItem>
                                        <MenuItem key="4" value="4">4</MenuItem>
                                        <MenuItem key="5" value="5">5</MenuItem>
                                        <MenuItem key="6" value="6">6</MenuItem>
                                    </Select>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <TextField
                            inputRef={register({ required: true })}
                            required
                            id={`ECTS${index}`}
                            name="ECTS"
                            label="Study programme total ECTS points"
                            fullWidth
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleECTSChange(e); setIsChanged(true); }}
                            value={ECTS}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth style={{ marginTop: "5px", }}>
                        <Controller
                            type="checkbox"
                            variant="outlined"
                            control={control}
                            refs={register}
                            name="doubleDegree"
                            defaultValue={doubleDegree}
                            render={({ onChange, onBlur, value }) => {
                                return (
                                    <FormControlLabel
                                        control={<Checkbox checked={doubleDegree} onChange={() => { setDoubleDegree(!doubleDegree); setIsChanged(true); }} name="doubleDegree" color="primary" />}
                                        label="Double major"
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth style={{ marginTop: "5px", }}>
                        <Controller
                            type="checkbox"
                            variant="outlined"
                            control={control}
                            refs={register}
                            name="scientificDegree"
                            defaultValue={scientificDegree}
                            render={({ onChange, onBlur, value }) => {
                                return (
                                    <FormControlLabel
                                        control={<Checkbox checked={scientificDegree} onChange={() => { setScientificDegree(!scientificDegree); setIsChanged(true); }} name="scientificDegree" color="primary" />}
                                        label="Scientific stream"
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                        freeSolo
                        defaultValue={titleOfDegree}
                        value={titleOfDegree}
                        onChange={(event: any, value: string | null) => { setTitleOfDegree(value); setIsChanged(true); }}
                        options={["Bachelor", "Master", "PhD"]}
                        renderInput={(params) => (
                            <TextField 
                                {...params}
                                inputRef={register({ required: true })}
                                required
                                id={`titleOfDegree${index}`}
                                name="titleOfDegree"
                                label="Degree title"
                                fullWidth
                                value={titleOfDegree}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setTitleOfDegree(capitalize(e.target.value)); setIsChanged(true); }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                        freeSolo
                        defaultValue={degreeLevel}
                        onChange={(event: any, value: string | null) => { setDegreeLevel(value); setIsChanged(true); }}
                        options={degreeLevelOptions}
                        renderInput={(params) => (
                            <TextField 
                                {...params}
                                inputRef={register({ required: true })}
                                required
                                id={`degreeLevel${index}`}
                                name="degreeLevel"
                                label="Degree level"
                                fullWidth
                                value={degreeLevel}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setDegreeLevel(e.target.value); setIsChanged(true); }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        inputRef={register({ required: true })}
                        required
                        id={`titleOfQualification${index}`}
                        name="titleOfQualification"
                        label="Qualification title"
                        fullWidth
                        value={titleOfQualification}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setTitleOfQualification(capitalize(e.target.value)); setIsChanged(true); }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        freeSolo
                        defaultValue={studyProgrammeLevels}
                        onChange={(event: any, value: string | null) => { setStudyProgrammeLevels(value); setIsChanged(true); }}
                        options={studyProgrammeLevelsOptions}
                        renderInput={(params) => (
                            <TextField 
                                {...params}
                                inputRef={register({ required: true })}
                                required
                                id={`studyProgrammeLevels${index}`}
                                name="studyProgrammeLevels"
                                label="Study programme levels"
                                fullWidth
                                value={studyProgrammeLevels}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStudyProgrammeLevels(e.target.value); setIsChanged(true); }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel variant="outlined">Academic credential type</InputLabel>
                        <Controller
                            type="select"
                            variant="outlined"
                            control={control}
                            refs={register({ required: true })}
                            name="fk_academicCredentialType"
                            defaultValue={academicCredentialType ? academicCredentialType : ""}
                            render={({ onChange, onBlur, value }) => {
                                return (
                                    <Select 
                                        onChange={(e: React.ChangeEvent<{value: unknown}>) => { setAcademicCredentialType(e.target.value as number); setValue("fk_academicCredentialType", e.target.value as string); setIsChanged(true); }}
                                        onBlur={onBlur}
                                        value={academicCredentialType ? academicCredentialType : ""}
                                        name="fk_academicCredentialType"
                                    >
                                        {institutionStore?.institution?.academicCredentialType?.map((institution) => (
                                            <MenuItem key={institution.academicCredentialTypeId} value={institution.academicCredentialTypeId}>
                                                {institution.academicCredentialTypeName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel variant="outlined">Type of student enrollment</InputLabel>
                        <Controller
                            type="select"
                            variant="outlined"
                            control={control}
                            refs={register({ required: true })}
                            name="fk_annualStudentEnrollmentQuota"
                            defaultValue={annualStudentEnrollmentQuotaId ? annualStudentEnrollmentQuotaId : []}
                            render={({ onChange, onBlur, value }) => {
                                return (
                                    <Select 
                                        onChange={(e) => { handleTypeOfStudentEnrollment(e); setIsChanged(true); } }
                                        onBlur={onBlur}
                                        value={annualStudentEnrollmentQuotaId ? annualStudentEnrollmentQuotaId : []}
                                        name="fk_annualStudentEnrollmentQuota"
                                        multiple
                                    >
                                        {institutionStore?.institution?.annualStudentEnrollmentQuota?.map((institution) => (
                                            <MenuItem key={institution.annualStudentEnrollmentQuotaId} value={institution.annualStudentEnrollmentQuotaId}>
                                                {institution.annualStudentEnrollmentQuotaName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Snackbar open={yearModal} autoHideDuration={6000} onClose={() => { setYearModal(false); }}>
				<Alert onClose={() => { setYearModal(false); }} severity="error">
					This field only accepts numbers.
				</Alert>
			</Snackbar>

            <Modal
				open={modal}
				onClose={handleModalClose}
				closeAfterTransition

				className="modal-confirmation"
			>
				<Fade in={modal}>
					<div className="modal-confirmation-container">
						<div className="modal-confirmation-icon-close-container">
							<IconClose
								className="modal-confirmation-icon-close"
								onClick={handleModalClose}
							/>
						</div>
						<div className="modal-confirmation-icon-container">
							<ErrorOutlineIcon color="secondary" style={{ fontSize: 50 }} />
						</div>
						<div className="modal-confirmation-text-container">
							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-title"
							>
								You are about to disable a text field
							</Typography>

							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-description"
							>
								Are you absolutely sure that you can't find the data about the study programme foundation year?
							</Typography>
						</div>
						<Grid
							container
							direction="row"
							className="modal-confirmation-actions-container"
							spacing={1}
						>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-confirm-container"
							>
								<Button
									size="small"
									variant="contained"
									className="delete-button"
									onClick={handleCheckbox}
								>
									Yes, I am sure
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-cancel-container"
							>
								<Button
									size="small"
									variant="outlined"
									color="primary"
									className="cancel-button"
									onClick={handleModalClose}
								>
									Cancel
								</Button>
							</Grid>
						</Grid>
					</div>
				</Fade>
			</Modal>
        </>
    );
}

export default StudyProgram;
