import React, { useState } from "react";

import {
	Card,
	CardContent,
	CardActionArea,
	Typography,
	Box,
	Grid,
	TextField,
	Button, 
	Container,
	Alert,
	Snackbar,
	FormControlLabel,
	Checkbox, 
	makeStyles,
} from "@material-ui/core";

import { 
	MapContainer,
	TileLayer, 
	Marker, 
	Popup, 
} from "react-leaflet";

import ReactFlagsSelect from "react-flags-select";

import axios from "axios";

import { useForm } from "react-hook-form";

import { observer } from "mobx-react-lite";

import { IInstitution } from "../../../../Models/Interfaces/IInstitutions";
import { getUserIdFromLoggedInUser } from "../../../Auth/Auth";
import "../../../../Style/index.css";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		[theme.breakpoints.up(((600 + theme.spacing(3)) as any) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},

	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	searchButton: {
		marginTop: "16px !important",
		marginLeft: "-15px !important",
		paddingTop: "15px !important",
		paddingBottom: "15px !important",
	},
	geocodeButton: {
		paddingTop: "15px !important",
		paddingBottom: "15px !important",
		padding: "10px !important",
	}
}));

const InstitutionOpenStreet = observer(({ setStep, formValues, institutionData, id, setFormValues }: any) => {
	//	Recovery from localStorage
	let local: any, recovery: any;
	if (!id) {
		local = localStorage.getItem('hejde_institution_location');
		recovery = JSON.parse(local);
	}

	//	React state
	const [latitude, setLatitude] = React.useState(
		formValues.lat ? formValues.lat : 
		!id && recovery ? recovery.lat : 
		institutionData ? institutionData.lat : 40.849065
	);
	const [longitude, setLongitude] = React.useState(
		formValues.lon ? formValues.lon :
		!id && recovery ? recovery.lon : 
		institutionData ? institutionData.lon : 14.486211
	);
	const [displayName, setDisplayName] = React.useState(institutionData ? "institutionData" : "");
		
	//	Check if step is fullfilled
	let isLocationSelected = false;
	if (formValues.lat && formValues.lon) {
		isLocationSelected = true;
	} else if (recovery) {
		if (recovery.lat && recovery.lon)
			isLocationSelected = true;
	}

	const {
		register,
		handleSubmit,
		formState: { isDirty },
		setValue,
	} = useForm<IInstitution>();

	const {
		register: registerSearch,
		handleSubmit: searchSubmit,
	} = useForm();

	const classes = useStyles();
	const userId = getUserIdFromLoggedInUser();
	const [mapLeaflet, setMapLeaflet]: any = useState(null);
	const datas = { ...formValues };
	const { nameOfTheInstitution } = datas;

	//	React state
	const [roadName, setRoadName] = React.useState(
		formValues.addressRoad ? formValues.addressRoad : 
		recovery ? recovery.addressRoad : ""
	);
	const [houseNumber, setHouseNumber] = React.useState(
		formValues.addressHouseNumber ? formValues.addressHouseNumber : 
		recovery ? recovery.addressHouseNumber : ""
	);
	const [municipality, setMunicipality] = React.useState("");
	const [countryCode, setCountryCode] = React.useState("");
	const [searchResults, setSearchResults] = React.useState<any>(null);
	const [isSelected, setIsSelected] = React.useState(isLocationSelected);
	const [selectedResult, setSelectedResult] = React.useState<any>(null);
	const [isLoaded, setIsLoaded] = React.useState(true);
	
	//	Snackbar controller
	const [alert, setAlert] = React.useState(false);
	const [inputModal, setInputModal] = React.useState(false);
	const [countryCodeModal, setCountryCodeModal] = React.useState(false);

	const facultyName = formValues.faculty;
	const isFaculty = formValues.isFaculty;
	const institutionName = formValues.nameOfTheInstitution;

	const [isDisabled, setIsDisabled] = React.useState(true);

	//	List of countries included in the dropdown menu
	const countries = ["AL","AD","AM","AT","AZ","BY","BE","BA","BG","HR","CY","CZ","DK","EE","FI","FR","GE","DE","GR","HU","IS","IE","IT","XK","LV","LI","LT","LU","MT","MD","MC","ME","NL","MK","NO","PL","PT","RO","RU","SM","RS","SK","SI","ES","SE","CH","TR","UA","GB","VA"];

	//	Initialize map with recovered data
	if (recovery)
		if (recovery.lat && recovery.lon && mapLeaflet)
			mapLeaflet.flyTo([Number(recovery.lat), Number(recovery.lon)], 12);

	React.useEffect(() => {
		if (!searchResults) {
			//	TomTom API
			axios.get(`https://api.tomtom.com/search/2/search/${!isFaculty ? facultyName : institutionName}.json?key=${process.env.REACT_APP_TOMTOM_API_KEY}&typeahead=true`).then((response) => {
				let results = [];
				for (let i = 0; i < response.data.results.length; i++) {
					if (response.data.results[i].type === "POI")
						results.push(response.data.results[i]);
				}
				setSearchResults(results);
			}).catch((error) => {
				console.error(error);
			});
		}
	}, [isLoaded]);

	if (institutionData) {
		console.log("institutionData", institutionData)
		if (!houseNumber)
			if (institutionData.addressHouseNumber)
				setHouseNumber(institutionData.addressHouseNumber);

		if (!roadName)
			if (institutionData.addressRoad)
				setRoadName(institutionData.addressRoad);

		if (!municipality)
			if (institutionData.addressCity)
				setMunicipality(institutionData.addressCity);

		if (!latitude)
			if (institutionData.lat)
				setLatitude(institutionData.lat);

		if (!latitude)
			if (institutionData.lon)
				setLongitude(institutionData.lon);

		if (!isSelected)
			if (institutionData.addressCountry)
				setIsSelected(true);
	}

	//	Fuzzy location search
	const requestNewInstitution = async (data: any) => {
		const datas = { ...data };
		console.log(datas);
		const { nameOfTheInstitution } = datas;

		//	TomTom API
		axios.get(`https://api.tomtom.com/search/2/search/${nameOfTheInstitution}.json?key=${process.env.REACT_APP_TOMTOM_API_KEY}&typeahead=true`).then((response) => {
			let results = [];
			for (let i = 0; i < response.data.results.length; i++) {
				if (response.data.results[i].type === "POI")
					results.push(response.data.results[i]);
			}
			setSearchResults(results);
		}).catch((error) => {
			console.error(error);
		});
	};

	//	Geocode
	const geocode = async () => {
		if (countryCode) {
			let query = `https://api.tomtom.com/search/2/structuredGeocode.json
						?key=${process.env.REACT_APP_TOMTOM_API_KEY}
						&countryCode=${countryCode}
						&typeahead=true`;

			if (roadName)
				query += `&streetName=${roadName}`;
			
			if (houseNumber)
				query += `&streetNumber=${houseNumber}`;

			if (municipality)
				query += `&municipality=${municipality}`;

			if (roadName || houseNumber || municipality) {
				//	TomTom API
				axios.get(query).then((response) => {
					let results = [];
					for (let i = 0; i < response.data.results.length; i++) {
						if (response.data.results[i].type === "POI" || response.data.results[i].type === "Point Address")
						results.push(response.data.results[i]);
					}
					setSearchResults(results);
				}).catch((error) => {
					console.error(error);
				});
			} else {
				setInputModal(true);
			}
		} else {
			setCountryCodeModal(true);
		}
	};

	const handleLocation = (result: any) => {
		let locationDetails: any = {
			"addressCountry": result.address.country,
			"addressCity": result.address.municipality,
			"addressPostcode": result.address.postalCode,
			"addressRoad": result.address.streetName,
			"addressHouseNumber": result.address.streetNumber,
			"lat": result.position.lat,
			"lon": result.position.lon,
		}
		setSelectedResult(locationDetails);

		//	Save draft to localStorage
		if (!id)
			localStorage.setItem('hejde_institution_location', JSON.stringify(locationDetails));
		
		if (result.address.country) {
			setValue("addressCountry", result.address.country);
		} else {
			setValue("addressCountry", "");
		}
			
		if (result.address.streetNumber) {
			setValue("addressHouseNumber", result.address.streetNumber);
			setHouseNumber(result.address.streetNumber);
		} else {
			setValue("addressHouseNumber", "");
			setHouseNumber("");
		}
			
		if (result.address.postalCode) {
			setValue("addressPostcode", result.address.postalCode);
		} else {
			setValue("addressPostcode", "");
		}

		if (result.address.streetName) {
			setValue("addressRoad", result.address.streetName);
			setRoadName(result.address.streetName);
		} else {
			setValue("addressRoad", "");
			setRoadName("");
		}

		if (result.address.municipality) {
			setValue("addressCity", result.address.municipality);
		} else {
			setValue("addressCity", "");
		}

		if (result.position.lat) {
			setValue("lat", result.position.lat);
			setLatitude(result.position.lat);
		} else {
			setValue("lat", "");
			setLatitude(0);
		}

		if (result.position.lon) {
			setValue("lon", result.position.lon);
			setLongitude(result.position.lon);
		} else {
			setValue("lon", "");
			setLongitude(0);
		}

		if (result.position.lat && result.position.lon)
			mapLeaflet.flyTo([Number(result.position.lat), Number(result.position.lon)], 12);

		setIsSelected(true);
	}

	const onSubmit = () => {
		if (isSelected) {
			formValues = { ...formValues, ...selectedResult };
			setFormValues(formValues);
			setStep(3);
		} else {
			setAlert(true);
		}
	};

	return (
		<>
			<Button
				style={{
					marginBottom: "4px",
				}}			
				onClick={() => {
					setStep(1);
				}}
			>
				Back
			</Button>
			<Container maxWidth="lg" className="institution-form-container">
				<Grid container spacing={3}>
					<Grid item xs={11}>
						<TextField
							id="searchField"
							label="Enter faculty name or institution name"
							inputRef={registerSearch}
							name="nameOfTheInstitution"
							defaultValue={!isFaculty ? facultyName : institutionName}
							fullWidth
							disabled={isDisabled}
							margin="normal"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={1}>
						<Button
							variant="contained"
							className={classes.searchButton}
							onClick={searchSubmit(requestNewInstitution)}
						>
							Search
						</Button>
					</Grid>
					<Grid item xs={12} style={{ paddingTop: "5px", }}>
						<FormControlLabel
							control={<Checkbox checked={!isDisabled} onChange={() => { setIsDisabled(!isDisabled); }} name="isDisabled" color="primary" />}
							label="Allow custom search"
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							inputRef={register}
							id="addressRoad"
							name="addressRoad"
							label="Street name"
							defaultValue={roadName}
							value={roadName}
							onChange={(e: any) => setRoadName(e.target.value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField
							inputRef={register}
							id="addressHouseNumber"
							name="addressHouseNumber"
							label="Street number"
							defaultValue={houseNumber}
							value={houseNumber}
							onChange={(e: any) => setHouseNumber(e.target.value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							inputRef={register}
							id="municipality"
							name="municipality"
							label="Municipality/city"
							defaultValue={municipality}
							value={municipality}
							onChange={(e: any) => setMunicipality(e.target.value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<ReactFlagsSelect
							countries={countries}
							selected={countryCode}
							onSelect={(code) => setCountryCode(code)}
						/>
					</Grid>
					<Grid item xs={12} sm={2} style={{ marginBottom: "12px", }}>
						<Button
							variant="contained"
							className={classes.geocodeButton}
							onClick={searchSubmit(geocode)}
							style={{ width: "100%", }}
						>
							Search by address
						</Button>
					</Grid>

					<form onSubmit={handleSubmit(onSubmit)} className="institution-form-leaflet">
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6} className="institution-form-location-list">
								{searchResults && searchResults.length !== 0 ? searchResults.map((result: any) => (
									<CardActionArea key={result.id} onClick={() => { handleLocation(result); }} className="institution-form-card-action">
										<Card className="institution-form-card">
											<CardContent>
												{result.type == "POI" ?
													<Typography variant="h5" component="h2" className="institution-form-city">
														{result.poi.name}
													</Typography>
												: null}

												<input type="hidden" name="lon" ref={register} value={result.position.lon} />
												<input type="hidden" name="lat" ref={register} value={result.position.lat} />
												<input type="hidden" name="addressCountry" ref={register} value={result.address.country} />
												<input type="hidden" name="addressPostcode" ref={register} value={result.address.postalCode} />
												<input type="hidden" name="addressCity" ref={register} value={result.address.municipality} />
												<input type="hidden" name="addressRoad" ref={register} value={result.address.streetName} />
												<input type="hidden" name="addressHouseNumber" ref={register} value={result.address.streetNumber} />
												<input type="hidden" name="institutionAddedByUserId" ref={register} value={userId} />

												<Typography variant="body2" color="textSecondary" component="p" className="institution-form-country">
													{result.address.streetName && result.address.streetNumber ? result.address.streetName + " " + result.address.streetNumber : ""}
													{result.address.streetName && !result.address.streetNumber ? result.address.streetName : ""}
												</Typography>

												<Typography variant="body2" color="textSecondary" component="p" className="institution-form-country">
													{(result.address.city, result.address.country)}
												</Typography>
											</CardContent>
										</Card>
									</CardActionArea>
								)) :
								<Typography variant="body2" color="textSecondary" component="p" className="institution-form-country">
									No results found, please try again.
								</Typography>
								}
							</Grid>
							<Grid item xs={12} sm={6}>
								<Grid container spacing={1} style={{ marginBottom: "12px", }}>
									<Grid item xs={12} style={{ marginBottom: "16px", }}>
										<MapContainer
											center={[latitude, longitude]}
											zoom={6}
											scrollWheelZoom={false}
											className="main-map-container"
											whenCreated={(map: any) => {
												map.invalidateSize();
												setMapLeaflet(map);
											}}
										>
											<TileLayer
												attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
												url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
											/>

											{isSelected ? 
												<Marker position={[latitude, longitude]}>
													<Popup>{displayName}</Popup>
												</Marker>
											: null}
										</MapContainer>
									</Grid>
								</Grid>

							</Grid>
						</Grid>
						<Grid item xs={12} style={{ paddingLeft: "24px" }}>
							<Grid container alignItems="center">
								<Grid item xs={9}>
									<Typography style={{ fontStyle: "italic" }}>
										{id ? "" : "Data is saved automatically, allowing you to continue working on your entry at a later date."}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Box display="flex" p={2} flexDirection="row-reverse" style={{ paddingRight: "0px", }}>
										<Button type="submit" variant="contained" color="primary">
											Next
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Grid>

					</form>
				</Grid>
				<Snackbar open={alert} autoHideDuration={6000} onClose={() => { setAlert(false); }}>
					<Alert onClose={() => { setAlert(false); }} severity="error">
						Please select a location.
					</Alert>
				</Snackbar>

				<Snackbar open={inputModal} autoHideDuration={6000} onClose={() => { setInputModal(false); }}>
					<Alert onClose={() => { setInputModal(false); }} severity="error">
						Please fill in at least one field (street name, street number, municipality).
					</Alert>
				</Snackbar>

				<Snackbar open={countryCodeModal} autoHideDuration={6000} onClose={() => { setCountryCodeModal(false); }}>
					<Alert onClose={() => { setCountryCodeModal(false); }} severity="error">
						Please select a country.
					</Alert>
				</Snackbar>
			</Container>
		</>
	);
});
export default InstitutionOpenStreet;
