import React, { useEffect, useState, } from "react";

import {
	Grid,
	Paper,
	Container,
	CssBaseline,
	makeStyles,
	useTheme,
} from "@material-ui/core";

import { observer } from "mobx-react-lite";

import Header from "./Header";
import ListOfUsers from "./ListOfUsers/ListOfUsers";
import SnackBar from "../Snackbar/Snackbar";

import { useSnackBarStore, useUserStore } from "../../Stores/Stores";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
}));

const Dashboard = observer(() => {
	const classes = useStyles();
	const theme = useTheme();
	const [fetched, setFetched] = useState(false);
	const snackbarStore = useSnackBarStore();
	const userStore = useUserStore();

	useEffect(() => {
		const getUsers = async () => {
			await userStore?.getUsers();
			setFetched(true);
		};
		getUsers();
	}, [userStore]);

	return (
		<div className={classes.root}>
			<Header />
			<CssBaseline />
			<SnackBar type={snackbarStore?.type} message={snackbarStore?.message} open={snackbarStore?.open} />

			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<ListOfUsers />
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</main>
		</div>
	);
});

export default Dashboard;
