import { useLocalObservable } from "mobx-react-lite";
import React from "react";
import PropTypes from "prop-types";
import InstitutionStore, { InstitutionStoreContext } from "../Stores/InstitutionStore";
interface IInstitutionProvider {}

const InstitutionProvider: React.FC<IInstitutionProvider> = (props) => {
	const localStore = useLocalObservable(() => new InstitutionStore());
	return <InstitutionStoreContext.Provider value={localStore}>{props.children}</InstitutionStoreContext.Provider>;
};

InstitutionProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
export default InstitutionProvider;
