import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";

import { 
	Button,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	makeStyles,
	Modal,
	Grid,
	Typography,
	Fade,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconClose from "@material-ui/icons/Close";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Link, useHistory } from "react-router-dom";

import { useUserStore } from "../../../Stores/Stores";

const useStyles = makeStyles((theme) => ({
	buttonIcon: {
		marginRight: "5px",
		marginLeft: "-5px",
	}
}));

export const ListOfUsers = observer(() => {
	const classes = useStyles();

	const userStore = useUserStore();
	const [users, setUsers] = React.useState([]);

	const [removeModal, setRemoveModal] = React.useState(false);
	const [userToDelete, setUserToDelete] = React.useState("-1");

	const userId = sessionStorage.getItem("userId");
	const userRole = sessionStorage.getItem("userRole");

	const history = useHistory();

	if (!userId) {
		history.push({
			pathname: "/login",
		});
	} else if (userRole !== "admin") {
		history.goBack();
	}

	useEffect(() => {
		const getUsers = async () => {
			await userStore?.getUsers();
			updateUsers();
		};
		getUsers();
	}, [userStore]);

	const updateUsers = () => {
		let phUsers: any = [];
		
		phUsers = userStore?.users.map((user) => (
			{
				"userId": user.userId,
				"roleDisplayName": user.roleDisplayName,
				"firstName": user.firstName,
				"lastName": user.lastName,
				"email": user.email,
				"registrationDate": user.registrationDate,
			}
		));

		if (phUsers) {
			phUsers.sort(function compare(a: any, b: any) {
				if (parseInt(a.userId) > parseInt(b.userId)) {
					return -1;
				} else if (parseInt(a.userId) < parseInt(b.userId)) {
					return 1;
				} else {
					return 0;
				}	
			});
		}

		setUsers(phUsers);
	}

	const handleRemoveModal = (user: any) => {
		setRemoveModal(true);
		setUserToDelete(user);
	}

	const handleRemoveModalClose = () => {
		setRemoveModal(false);
	}

	const removeUser = async () => {
		const user = userToDelete;
		if (user)
			await userStore?.removeUser(user);

		setRemoveModal(false);
		window.location.reload();
	}

	return (
		<React.Fragment>
			{userRole === "admin" ? 
				<>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow key="header">
									<TableCell style={{ width: '10%', fontWeight: 700 }}>First name</TableCell>
									<TableCell style={{ width: '10%', fontWeight: 700 }}>Last Name</TableCell>
									<TableCell style={{ width: '25%', fontWeight: 700 }}>Email</TableCell>
									<TableCell style={{ width: '20%', fontWeight: 700 }}>Registration date</TableCell>
									<TableCell style={{ width: '10%', fontWeight: 700 }}>Role</TableCell>
									<TableCell style={{ width: '15%'}} />
									<TableCell style={{ width: '10%'}} />
								</TableRow>
							</TableHead>
							<TableBody>
								{users.map((user: any) => (
									<TableRow className={user.roleDisplayName === "Pending" ? "list-of-users-recent" : ""} key={user.userId}>
										<TableCell>{user.firstName}</TableCell>
										<TableCell>{user.lastName}</TableCell>
										<TableCell>{user.email}</TableCell>
										<TableCell>{user.registrationDate}</TableCell>
										<TableCell>{user.roleDisplayName ? user.roleDisplayName : ""}</TableCell>
										<TableCell>
											<Button variant="outlined" component={Link} to={`/dashboard/edit-roles/${user.userId}`}>
												<EditIcon className={classes.buttonIcon} />
												Edit role
											</Button>
										</TableCell>
										<TableCell>
											<Button variant="outlined" onClick={() => handleRemoveModal(user.userId)}>
												<HighlightOffIcon className={classes.buttonIcon} />
												Remove
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Modal
						open={removeModal}
						onClose={handleRemoveModalClose}
						closeAfterTransition

						className="modal-confirmation"
					>
						<Fade in={removeModal}>
							<div className="modal-confirmation-container">
								<div className="modal-confirmation-icon-close-container">
									<IconClose
										className="modal-confirmation-icon-close"
										onClick={handleRemoveModalClose}
									/>
								</div>
								<div className="modal-confirmation-icon-container">
									<ErrorOutlineIcon color="secondary" style={{ fontSize: 50 }} />
								</div>
								<div className="modal-confirmation-text-container">
									<Typography
										variant="body2"
										color="textSecondary"
										className="modal-confirmation-title"
									>
										You are about to remove this user
									</Typography>

									<Typography
										variant="body2"
										color="textSecondary"
										className="modal-confirmation-description"
									>
										This user will be permanentely removed. This cannot be undone. Are you sure you want to remove this user?
									</Typography>
								</div>
								<Grid
									container
									direction="row"
									className="modal-confirmation-actions-container"
									spacing={1}
								>
									<Grid
										item
										xs={12}
										sm={6}
										className="modal-confirmation-actions-confirm-container"
									>
										<Button
											size="small"
											variant="contained"
											className="delete-button"
											onClick={removeUser}
										>
											Remove user
										</Button>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										className="modal-confirmation-actions-cancel-container"
									>
										<Button
											size="small"
											variant="outlined"
											color="primary"
											className="cancel-button"
											onClick={handleRemoveModalClose}
										>
											Cancel, keep user
										</Button>
									</Grid>
								</Grid>
							</div>
						</Fade>
					</Modal>
				</>
			: null}
		</React.Fragment>
	);
});
export default ListOfUsers;
