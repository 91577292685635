import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { createContext } from "react";
import { IUser } from "../Models/Interfaces/IUser";
import { UserService } from "../Services/UserService";

export default class UserStore {
  
    user!: IUser | null
    users = [] as IUser[]

    constructor(){
        makeObservable(this, {
            user: observable,
            users:observable,
            setUser: action,
            getUsers: action,
            setUsers:action,
            changeUserStatus:action,
            getUserById:action,
            removeUser: action,
        })

    }
    
    setUser(user: IUser){
        this.user=user;
    }

    async getUsers(){
        const response = await UserService.getUsers();
        if (response.ok){
            const usersData = await response.json().then((val)=>{return val});
            this.users= usersData.data.sort(this.compare) as IUser[];
            return response.status
        }
        else {
            return response.status;
        }
    }

    setUsers(users:IUser[]) {
        this.users=users
    }

    async changeUserStatus(userId:string, roleId:string) {
        const response = await UserService.changeUserStatus(userId, roleId);
        return response.status;
    }

    getUserById(userId:string) {
        const filter = this.users.filter((user)=>user.userId==userId)
        if (filter.length===0){
            return;
        }
        else {
            return filter[0]
        }
    }

    async removeUser(userId: string) {
        const response = await UserService.removeUser(userId);
        return response;
    }

    //sort in ASC order
    compare(a?: IUser, b?:IUser) {
        if (a?.firstName! < b?.firstName!){
          return -1;
        }
        if (a?.firstName! > b?.firstName!){
          return 1;
        }
        return 0;
    }

}
export const UserStoreContext=createContext<UserStore|null>(null)