import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import { ILogin } from "../Models/Interfaces/ILogin";
import { IUser } from "../Models/Interfaces/IUser";
import { AuthService } from "../Services/AuthService";
export default class AuthStore {
	login?: ILogin;
	constructor() {
		makeObservable(this, {
			login: observable,
			loginUser: action,
			registerUser: action,
			logout: action,
		});
	}

	async loginUser(user: ILogin) {
		try {
			const response = await AuthService.loginUser(user);
			if (response.status === "OK") {
				return response;
			} else {
				return response;
			}
		} catch (error) {
			throw error;
		}
	}

	async registerUser(user: IUser) {
		try {
			const response = await AuthService.registerUser(user);
			if (response.status === "OK") {
				return response.status;
			} else {
				return response;
			}
		} catch (error) {
			throw error;
		}
	}
	async logout() {
		try {
			const response = await AuthService.logout();
			return response;
		} catch (error) {
			throw error;
		}
	}
}
export const AuthStoreContext = createContext<AuthStore | null>(null);
