import { EApi } from "../Models/Enums/EApi"
import { generateAPIoptions } from "./OptionsHelpser"

export class InstitutionService {

    static getInstitutions = async ():Promise<Response> =>{
        try {
            const options = generateAPIoptions(EApi.GET, null);
            const response = await fetch (EApi.URL+"/institution", options);
            return response;
        } catch(error) {
            throw new Error(error);
        }
    }

    static getCodeList = async (): Promise<Response> =>{
        try {
            const options = generateAPIoptions(EApi.GET, null);
            const response = await fetch (EApi.URL+"/codeList", options);
            return response;
        } catch(error) {
            throw new Error(error);
        }
    }

    static getInstititionWithStreetMap = async(institutionName?:string): Promise<Response> => {
        try {
            return await fetch (EApi.OPENSTREET_URL+institutionName, {method: "GET"});
        } catch(error) {
            throw new Error(error);
        }
    }

    static createInstitution = async (institution: InstitutionService) => {
        try {
            const options = generateAPIoptions(EApi.POST, institution);
            const response = await fetch (EApi.URL+"/institution", options);
            const institutionData = await response.json().then((val)=>{return val});
            return institutionData;
        } catch(error) {
            throw new Error(error);
        }
    }

    static updateInstitution = async (institution: InstitutionService) => {
        try {
            const options = generateAPIoptions(EApi.PUT, institution);
            const response = await fetch (EApi.URL+"/institution", options);
            const institutionData = await response.json().then((val)=>{return val});
            return institutionData;
        } catch(error) {
            throw new Error(error);
        }
    }

    static removeInstitution = async (institutionId: string) => {
        try {
            const payload = { institutionId: institutionId };
            const options = generateAPIoptions(EApi.DELETE, payload);
            const response = await fetch (EApi.URL+"/institution", options)
            return response;
        } catch(error) {
            throw new Error(error);
        }
    }
}