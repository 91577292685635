import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import { AuthService } from "../../Services/AuthService";
import { ILogin } from "../../Models/Interfaces/ILogin";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useAuthStore, useUserStore } from "../../Stores/Stores";
import { IUser } from "../../Models/Interfaces/IUser";
import AuthProvider from "../../Providers/AuthProvider";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export const Login = observer(() => {
	const classes = useStyles();
	const history = useHistory();
	const authStore = useAuthStore();
	const [message, setMessage] = useState("");
	const { register, handleSubmit } = useForm<ILogin>();
	const userStore = useUserStore();
	const onSubmit = (data: ILogin) => {
		if (data != null || undefined) loginUser(data);
	};

	const loginUser = async (user: ILogin) => {
		const response = await authStore?.loginUser(user);
		if (response.status === "OK") {
			userStore?.setUser(response.data as IUser);

			let role = sessionStorage.getItem("userRole");
			
			if (role === "admin") {
				history.push("/dashboard");
			} else {
				history.push("/dashboard/institution");
			}
		} else {
			setMessage(response.data);
		}
	};
	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
					<TextField
						helperText={message}
						variant="outlined"
						margin="normal"
						inputRef={register({ required: true })}
						required
						fullWidth
						error={message !== ""}
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						inputRef={register({ required: true })}
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
					/>
					<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
					<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
						Sign In
					</Button>
					<Grid container>
						{/* <Grid item xs>
							<Link href="#" variant="body2">
								Forgot password?
							</Link>
						</Grid> */}
						<Grid item xs={6}>
							<Grid container style={{ marginTop: "6px" }}>
								<Link href="/register" variant="body2" style={{ fontSize: "12px", }}>
									{"Don't have an account? Sign up"}
								</Link>
							</Grid>
						</Grid>
						<Grid xs={6}>
							<Grid container justifyContent="flex-end" style={{ marginTop: "6px" }}>
								<Link href="/reset-password" variant="body2" style={{ fontSize: "12px", }}>
									{"Forgot password?"}
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</div>
		</Container>
	);
});
export default Login;
