import React from "react";

import { 
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	makeStyles,
	Grid,
    CssBaseline,
    Container,
    Paper
} from "@material-ui/core";

import axios from "axios";

import { useSnackBarStore } from "../../Stores/Stores";

import Header from "../Dashboard/Header";
import SnackBar from "../Snackbar/Snackbar";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
}));

function Collaborators() {
    const classes = useStyles();

    //  React state
	const [collaborators, setCollaborators] = React.useState([]);

	if (collaborators.length == 0)
		//	GET /collaborators
		axios.get("https://hejde.um.si/api/collaborators").then((res) => {
			setCollaborators(sort(res.data.data));
		}).catch((error) => console.log(error));

	//	Sort collaborators alphabetically
	const sort = (collaborators) => {
		return collaborators.sort((a, b) => a.lastName.localeCompare(b.lastName));
	}

    return (
		<div className={classes.root}>
			<Header />
			<CssBaseline />

            <main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
                            <TableContainer>
						<Table>
							<TableHead>
								<TableRow key="header">
									<TableCell style={{ fontWeight: 700 }}>Collaborator</TableCell>
									<TableCell style={{ fontWeight: 700 }}>Institution</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{collaborators.map((user) => (
									<TableRow key={user.userId}>
										<TableCell>{user.lastName + ", " + user.firstName}</TableCell>
                                        <TableCell>{user.titleOfTheInstitution}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</main>
        </div>
    );
}

export default Collaborators;