import { AlertProps } from "@material-ui/core";
import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";

export default class SnackbarStore {
	message?:string
    open?:boolean
    type: AlertProps["severity"]

	constructor() {
		makeObservable(this, {
			message: observable,
            open: observable,
            type:observable,
            hideSnackbar: action,
            setSnackbar:action
		});
	}

    setSnackbar(message:string, open:boolean,type:AlertProps["severity"]){
        this.message=message;
        this.open=open;
        this.type=type;
    }

    hideSnackbar(){
        this.open=false
    }

}

export const SnackBarContext=createContext<SnackbarStore|null>(null)