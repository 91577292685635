import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { 
	Box,
	Button, 
	FormControl, 
	Grid, 
	InputLabel, 
	MenuItem, 
	Select, 
	TextField,
	FormControlLabel,
	Checkbox, 
	Typography, 
	Divider, 
	Modal, 
	Fade, 
	Snackbar, 
	Alert, 
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconClose from "@material-ui/icons/Close";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {
    MapContainer,
    TileLayer,
    Marker,
} from "react-leaflet";

import { observer } from "mobx-react-lite";

import { useInstitutionStore } from "../../../../Stores/Stores";
import { IInstitution } from "../../../../Models/Interfaces/IInstitutions";
import StudyProgram from "./StudyProgram";

const InstitutionFormDetails = observer(({ setStep, formValues, institutionData, setFormValues }: any) => {
	const institutionStore = useInstitutionStore();
	const data = { ...formValues };
	const history = useHistory();
	const [fetched, setFetched] = useState(false);
	const { control, register, handleSubmit, setValue } = useForm<IInstitution>();

	const [isChanged, setIsChanged] = React.useState(false);

	const [removeModal, setRemoveModal] = React.useState(false);
	const [removeIndex, setRemoveIndex] = React.useState<any>(null);

	const [filledModal, setFilledModal] = React.useState(false);
	const [ECTSModal, setECTSModal] = React.useState(false);

	const params: any = useParams();
	const id = params.id;

	let religiousInstitutionTypeId;
	if (formValues.isReligious !== undefined) {
		religiousInstitutionTypeId = formValues.isReligious;
	} else {
		if (institutionData) {
			if (institutionData.religiousInstitutionTypeId) {
				religiousInstitutionTypeId = true;
			} else {
				religiousInstitutionTypeId = false;
			}
		} else {
			religiousInstitutionTypeId = false;
		}
	}

	let JSON_string: any;
	if (!id) {
		JSON_string = localStorage.getItem("studyProgrammes");
	} else {
		JSON_string = sessionStorage.getItem("studyProgrammes");
	}
	const programmes = JSON.parse(JSON_string);
	if (!programmes) {
		if (formValues.studyPrograms) {
			let programmes = [];
			for (let i = 0; i < formValues.studyPrograms.length; i++) {
				programmes.push(formValues.studyPrograms[i].index);

				let isFilled = false;
				if (
					formValues.studyPrograms[i].nameOfTheStudyProgram && formValues.studyPrograms[i].studyProgramWebsiteLink 
					&& formValues.studyPrograms[i].yearOfFoundationOfTheStudyProgram && formValues.studyPrograms[i].ECTS 
					&& formValues.studyPrograms[i].titleOfDegree && formValues.studyPrograms[i].titleOfQualification 
					&& formValues.studyPrograms[i].yearsAfterPrimarySecondarySchool && formValues.studyPrograms[i].yearsOfTheStudyProgramDuration
					&& formValues.studyPrograms[i].fk_annualStudentEnrollmentQuota && formValues.studyPrograms[i].fk_studyDegreeRegardingEQFNQF
					&& formValues.studyPrograms[i].fk_typeOfStudyProgram
				)
					isFilled = true;

				const studyProgram = {
					nameOfTheStudyProgram: formValues.studyPrograms[i].nameOfTheStudyProgram,
					studyProgramWebsiteLink: formValues.studyPrograms[i].studyProgramWebsiteLink,
					yearOfFoundationOfTheStudyProgram: formValues.studyPrograms[i].yearOfFoundationOfTheStudyProgram,
					ECTS: formValues.studyPrograms[i].ECTS,
					titleOfDegree: formValues.studyPrograms[i].titleOfDegree,
					titleOfQualification: formValues.studyPrograms[i].titleOfQualification,
					yearsAfterPrimarySecondarySchool: formValues.studyPrograms[i].yearsAfterPrimarySecondarySchool,
					yearsOfTheStudyProgramDuration: formValues.studyPrograms[i].yearsOfTheStudyProgramDuration,
					fk_annualStudentEnrollmentQuota: formValues.studyPrograms[i].fk_annualStudentEnrollmentQuota,
					fk_studyDegreeRegardingEQFNQF: formValues.studyPrograms[i].fk_studyDegreeRegardingEQFNQF,
					fk_typeOfStudyProgram: formValues.studyPrograms[i].fk_typeOfStudyProgram,
					isFilled: isFilled,
					index: formValues.studyPrograms[i].index,
				};
		
				if (!id) {
					localStorage.setItem(`studyProgram${formValues.studyPrograms[i].index}`, JSON.stringify(studyProgram));
				} else {
					sessionStorage.setItem(`studyProgram${formValues.studyPrograms[i].index}`, JSON.stringify(studyProgram));
				}
			}

			if (!id) {
				localStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			} else {
				sessionStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			}
		} else if (institutionData && institutionData.studyPrograms) {
			let programmes = [];
			for (let i = 0; i < institutionData.studyPrograms.length; i++) {
				programmes.push(i);		

				let isFilled = false;
				if (
					institutionData.studyPrograms[i].nameOfTheStudyProgram && institutionData.studyPrograms[i].studyProgramWebsiteLink 
					&& institutionData.studyPrograms[i].yearOfFoundationOfTheStudyProgram && institutionData.studyPrograms[i].ECTS 
					&& institutionData.studyPrograms[i].titleOfDegree && institutionData.studyPrograms[i].titleOfQualification 
					&& institutionData.studyPrograms[i].yearsAfterPrimarySecondarySchool && institutionData.studyPrograms[i].yearsOfTheStudyProgramDuration
					&& institutionData.studyPrograms[i].annualstudentenrollmentquota && institutionData.studyPrograms[i].studyDegreeRegardingEQFNQFId
					&& institutionData.studyPrograms[i].typeOfStudyProgramId
				)
					isFilled = true;

				let annualstudentenrollmentquota: string[] = [];
				for (let j = 0; j < institutionData.studyPrograms[i].annualstudentenrollmentquota.length; j++) {
					annualstudentenrollmentquota.push(institutionData.studyPrograms[i].annualstudentenrollmentquota[j].annualStudentEnrollmentQuotaId);
				}
		
				const studyProgram = {
					nameOfTheStudyProgram: institutionData.studyPrograms[i].nameOfTheStudyProgram,
					studyProgramWebsiteLink: institutionData.studyPrograms[i].studyProgramWebsiteLink,
					yearOfFoundationOfTheStudyProgram: institutionData.studyPrograms[i].yearOfFoundationOfTheStudyProgram,
					ECTS: institutionData.studyPrograms[i].ECTS,
					titleOfDegree: institutionData.studyPrograms[i].titleOfDegree,
					titleOfQualification: institutionData.studyPrograms[i].titleOfQualification,
					yearsAfterPrimarySecondarySchool: institutionData.studyPrograms[i].yearsAfterPrimarySecondarySchool,
					yearsOfTheStudyProgramDuration: institutionData.studyPrograms[i].yearsOfTheStudyProgramDuration,
					fk_annualStudentEnrollmentQuota: annualstudentenrollmentquota,
					fk_studyDegreeRegardingEQFNQF: institutionData.studyPrograms[i].studyDegreeRegardingEQFNQFId,
					fk_typeOfStudyProgram: institutionData.studyPrograms[i].typeOfStudyProgramId,
					isFilled: isFilled,
					index: i,
				};
		
				if (!id) {
					localStorage.setItem(`studyProgram${i}`, JSON.stringify(studyProgram));
				} else {
					sessionStorage.setItem(`studyProgram${i}`, JSON.stringify(studyProgram));
				}
			}

			if (!id) {
				localStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			} else {
				sessionStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			}
		}
	}

	let defaultIndex, defaultStudyPrograms = [];
	let studyProgrammesStringified: any 
	if (!id) {
		studyProgrammesStringified = localStorage.getItem("studyProgrammes");
	} else {
		studyProgrammesStringified = sessionStorage.getItem("studyProgrammes");
	}
	const studyProgrammes = JSON.parse(studyProgrammesStringified);

	if (studyProgrammes) {
		defaultIndex = studyProgrammes.length;
		for (let i = 0; i < studyProgrammes.length; i++) {
			defaultStudyPrograms.push({
				program: <StudyProgram index={studyProgrammes[i]} id={id} />,
				index: studyProgrammes[i],
			});
		}
	} else {
		defaultStudyPrograms.push({
			program: <StudyProgram index={0} />, 
			index: 0,
		});
	}

	//	React state
	const [inData, setInData] = React.useState<any>(null);
	const [faculty, setFaculty] = React.useState(formValues.faculty ? formValues.faculty : "");
	const [studyPrograms, setStudyPrograms] = React.useState<any[]>(defaultStudyPrograms);
	const [index, setIndex] = React.useState(defaultIndex);
	const [religiousTypeId, setReligiousTypeId] = React.useState<Number | null>(formValues.fk_religiousInstitutionType ? formValues.fk_religiousInstitutionType : null);
	const [isReligious, setIsReligious] = React.useState(religiousInstitutionTypeId ? true : false);
	const [higherEducationInstitutionType, setHigherEducationInstitutionType] = React.useState(
		formValues.fk_higherEducationInstitutionType ? formValues.fk_higherEducationInstitutionType :
		institutionData ? institutionData.higherEducationInstitutionTypeId : ""
	);

	const [isFaculty, setIsFaculty] = React.useState(formValues.isFaculty ? formValues.isFaculty : false);
	const [modal, setModal] = React.useState(false);

	//	Institution data from database
	React.useEffect(() => {
		if (institutionData)
			setInData(institutionData);
	}, [institutionData]);

	//	Update isFaculty checkbox
	React.useEffect(() => {
		if (inData)
			if (!inData.faculty && !faculty)
				setIsFaculty(true);
	}, [faculty, inData]);

	useEffect(() => {
		const getCodeList = async () => {
			await institutionStore?.getCodeList();
			setFetched(true);
			institutionStore?.getAreaName(data.fk_academicArea);
			console.log(data.fk_academicArea);
			console.log(institutionStore?.getAreaName(data.fk_academicArea));
		};
		getCodeList();
	}, [institutionStore]);

	if (institutionData && !isChanged) {
		if (!religiousTypeId && !formValues.fk_religiousInstitutionType)
			if (institutionData.religiousInstitutionTypeId) {
				setReligiousTypeId(institutionData.religiousInstitutionTypeId);
				if (formValues.isReligious === undefined) {
					setIsReligious(true);
					setValue("isReligious", true);
				}
			}
	}

	//	POST /institution
	const createInstitution = async (payload: IInstitution) => {
		const response = await institutionStore?.createInstitution(payload);
		console.log(response.error);
	}

	//	PUT /institution
	const updateInstitution = async (payload: IInstitution) => {
		const response = await institutionStore?.updateInstitution(payload);
		console.log(response.error);
	}

	//	Submit handler
	const onSubmit = (data: IInstitution) => {
		console.log({ ...formValues, ...data });
	
		setFormValues({ ...formValues, ...data });

		let isFilled = true, isValid = true, SPIndex: any = "", JSON_SPIndex: any;
		//	IF entering new data, fetch from localStorage
		//	ELSE editing existing data, fetch from sessionStorage
		if (!id) {
			SPIndex = localStorage.getItem("studyProgrammes") ? localStorage.getItem("studyProgrammes") : "";
		} else {
			SPIndex = sessionStorage.getItem("studyProgrammes") ? sessionStorage.getItem("studyProgrammes") : "";
		}
		
		if (SPIndex !== "")
			JSON_SPIndex = JSON.parse(SPIndex);

		if (JSON_SPIndex)
			for (let i = 0; i < JSON_SPIndex.length; i++) {
				let JSON_string: any;
				if (!id) {
					JSON_string = localStorage.getItem(`studyProgram${JSON_SPIndex[i]}`) ? localStorage.getItem(`studyProgram${JSON_SPIndex[i]}`) : "";
				} else {
					JSON_string = sessionStorage.getItem(`studyProgram${JSON_SPIndex[i]}`) ? sessionStorage.getItem(`studyProgram${JSON_SPIndex[i]}`) : "";
				}

				if (JSON_string !== "") {
					const JSON_object: any = JSON.parse(JSON_string);
		
					if (!JSON_object.isFilled) {
						isFilled = false;
						setFilledModal(true);
						break;
					}

					if (JSON_object.ECTS < 30 || JSON_object.ECTS > 1200) {
						isValid = false;
						setECTSModal(true);
					}
				}
			}

		let studyProgrammeData: any = [];
		if (isFilled && isValid && JSON_SPIndex) {
			for (let i = 0; i < JSON_SPIndex.length; i++) {
				let JSON_string: any;
				if (!id) {
					JSON_string = localStorage.getItem(`studyProgram${JSON_SPIndex[i]}`) ? localStorage.getItem(`studyProgram${JSON_SPIndex[i]}`) : "";
				} else {
					JSON_string = sessionStorage.getItem(`studyProgram${JSON_SPIndex[i]}`) ? sessionStorage.getItem(`studyProgram${JSON_SPIndex[i]}`) : "";
				}

				if (JSON_string !== "") {
					const JSON_object: any = JSON.parse(JSON_string);
					studyProgrammeData.push(JSON_object);
				}
			}

			const finalStudyPrograms = { studyPrograms: studyProgrammeData };
			formValues = { ...formValues, ...finalStudyPrograms };
			setFormValues(formValues);
		}

		const payload = { ...formValues, ...data };
		payload.studyPrograms = studyProgrammeData;

		if (isFilled && isValid) {
			if (!institutionData) {
				//	Create institution
				//	POST /institution
				createInstitution(payload);
				history.push("/dashboard/institution/");
			} else {
				//	Update institution
				//	PUT /institution
				const finalData = { ...payload, institutionId: id };
				updateInstitution(finalData);
				history.push("/dashboard/institution/");
			}

			//	localStorage/sessionStorage cleanup
			if (JSON_SPIndex)
				for (let i = 0; i < JSON_SPIndex.length; i++) {
					//	IF entering new data, remove from localStorage
					//	ELSE editing existing data, remove from sessionStorage
					if (!id) {
						if (localStorage.getItem(`studyProgram${JSON_SPIndex[i]}`))
							localStorage.removeItem(`studyProgram${JSON_SPIndex[i]}`);

						if (localStorage.getItem("studyProgrammes"))
							localStorage.removeItem("studyProgrammes");					
						
						if (localStorage.getItem('hejde_institution_details'))
							localStorage.removeItem('hejde_institution_details');

						if (localStorage.getItem('hejde_institution_location'))
							localStorage.removeItem('hejde_institution_location');
					} else {
						if (sessionStorage.getItem(`studyProgram${JSON_SPIndex[i]}`))
							sessionStorage.removeItem(`studyProgram${JSON_SPIndex[i]}`);

						if (sessionStorage.getItem("studyProgrammes"))
							sessionStorage.removeItem("studyProgrammes");
					}
				}
		}
	}

	let contextOfEducationalPoliciesId;
	if (institutionData) {
		switch (institutionData.contextOfEducationalPoliciesName) {
			case "Agricultural Sciences":
				contextOfEducationalPoliciesId = 1;
				break;
			case "Humanities":
				contextOfEducationalPoliciesId = 2;
				break;
			case "Interdisciplinary sciences":
				contextOfEducationalPoliciesId = 3;
				break;
			case "Medical & Health Sciences":
				contextOfEducationalPoliciesId = 4;
				break;
			case "Natural Sciences":
				contextOfEducationalPoliciesId = 5;
				break;
			case "Social sciences":
				contextOfEducationalPoliciesId = 6;
				break;	
			case "Technical sciences":
				contextOfEducationalPoliciesId = 7;
				break;
		}
	}

	let defaultDepartment;
	if (data.department) {
		defaultDepartment = data.department;
	} else if (institutionData) {
		if (institutionData.department) {
			defaultDepartment = institutionData.department;
		}
	}
	
	let defaultFaculty;
	if (data.faculty) {
		defaultFaculty = data.faculty;
	} else if (institutionData) {
		if (!institutionData.isFaculty)
			defaultFaculty = institutionData.faculty;
	}

	//	Default location data
	let addressHouseNumber = "", addressRoad = "", addressCountry = "", lat = "", lon = "";
	if (institutionData) {
		if (institutionData.addressHouseNumber && !data.addressRoad)
			addressHouseNumber = institutionData.addressHouseNumber;
		if (institutionData.addressRoad)
			addressRoad = institutionData.addressRoad;
		if (institutionData.addressCountry)
			addressCountry = institutionData.addressCountry;
		if (institutionData.lat)
			lat = institutionData.lat;
		if (institutionData.lon)
			lon = institutionData.lon;
	} else if (!id) {
		let local: any = localStorage.getItem('hejde_institution_location');
		let recovery: any = JSON.parse(local);

		lat = recovery.lat;
		lon = recovery.lon;
		addressHouseNumber = recovery.addressHouseNumber;
		addressRoad = recovery.addressRoad;
		addressCountry = recovery.addressCountry;
	}

	//	Set fk_religiousInstitutionType value in formValues
	React.useEffect(() => {
		if (!isReligious) {
			setValue("fk_religiousInstitutionType", null);
		} else {
			setValue("fk_religiousInstitutionType", religiousTypeId);
		}
	}, [isReligious]);

	//	Add new study programme to localStorage/sessionStorage
	const addStudyProgram = () => {
		const programs = [...studyPrograms];

		programs.push({
			program: <StudyProgram index={index} />,
			index: index,
		});

		const studyProgram = {
			nameOfTheStudyProgram: "",
			studyProgramWebsiteLink: "",
			yearOfFoundationOfTheStudyProgram: "",
			ECTS: "",
			titleOfDegree: "",
			titleOfQualification: "",
			yearsAfterPrimarySecondarySchool: "",
			yearsOfTheStudyProgramDuration: "",
			fk_annualStudentEnrollmentQuota: [],
			fk_studyDegreeRegardingEQFNQF: "",
			fk_typeOfStudyProgram: "",
			isFilled: false,
			index: index,
		};

		let programmes_string: any;
		if (!id) {
			programmes_string = localStorage.getItem("studyProgrammes");
		} else {
			programmes_string = sessionStorage.getItem("studyProgrammes");
		}

		let programmes: any[] = JSON.parse(programmes_string);
		programmes.push(index);

		if (!id) {
			localStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			localStorage.setItem(`studyProgram${index}`, JSON.stringify(studyProgram));
		} else {
			sessionStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			sessionStorage.setItem(`studyProgram${index}`, JSON.stringify(studyProgram));
		}

		let phIndex = index + 1;
		setIndex(phIndex++);

		setStudyPrograms(programs);
		setIsChanged(true);
	}

	//	Remove study programme from localStorage/sessionStorage
	const removeStudyProgram = () => {
		const programs = [...studyPrograms];
		if (removeIndex !== 0) {
			const currentIndex = studyPrograms[removeIndex].index;
			
			let programmes_string: any;
			if (!id) {
				programmes_string = localStorage.getItem("studyProgrammes");
			} else {
				programmes_string = sessionStorage.getItem("studyProgrammes");
			}

			let programmes: any[] = JSON.parse(programmes_string);
			
			for (let i = 0; i < programmes.length; i++) {
				if (programmes[i] === currentIndex) {
					programmes.splice(i, 1);
					break;
				}
			}

			if (!id) {
				localStorage.setItem("studyProgrammes", JSON.stringify(programmes));
				localStorage.removeItem(`studyProgram${currentIndex}`);
			} else {
				sessionStorage.setItem("studyProgrammes", JSON.stringify(programmes));
				sessionStorage.removeItem(`studyProgram${currentIndex}`);
			}
			
			programs.splice(removeIndex, 1);
		}

		setStudyPrograms(programs);
		setRemoveIndex(null);
		setRemoveModal(false);
		setIsChanged(true);
	}

	const handleRemoveModal = (index: any) => {
		setRemoveModal(true);
		setRemoveIndex(index);
	}
	
	const handleRemoveModalClose = () => {
		setRemoveModal(false);
	}

	const handleCheckbox = () => {
		setIsFaculty(!isFaculty);
		setValue("isFaculty", !isFaculty); 
		setModal(false);
	}

	const handleModal = () => {
		if (!isFaculty) {
			setModal(true);
		} else {
			setIsFaculty(!isFaculty);
			setValue("isFaculty", !isFaculty); 
		}
	}
	
	const handleModalClose = () => {
		setModal(false);
	}

	return (
		<>
			<Button
				style={{
					marginBottom: "4px",
				}}
				onClick={() => {
					setStep(3);
				}}
			>
				Back
			</Button>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h6">
							Institution
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							inputRef={register({ required: true })}
							required
							defaultValue={data.nameOfTheInstitution === "" ? institutionStore?.institution?.nameOfTheInstitution : data.nameOfTheInstitution}
							id="nameOfTheInstitution"
							name="nameOfTheInstitution"
							label="Institution name"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl required fullWidth>
							<InputLabel variant="outlined">Institution area</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								name="fk_academicArea"
								defaultValue={data.fk_academicArea ? data.fk_academicArea : institutionData.academicAreaId}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select onChange={onChange} onBlur={onBlur} value={value ?? ""} name="fk_academicArea">
											{institutionStore?.institution?.academicArea?.map((institution) => (
												<MenuItem key={institution.academicAreaId} value={institution.academicAreaId}>
													{institution.academicAreaName}
												</MenuItem>
											))}
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={2}>
						<FormControl fullWidth style={{ marginTop: "5px", }}>
							<Controller
								type="checkbox"
								variant="outlined"
								control={control}
								refs={register}
								name="isFaculty"
								defaultValue={isFaculty}
								render={({ onChange, onBlur, value }) => {
									return (
										<FormControlLabel
											control={<Checkbox checked={isFaculty} onChange={() => { handleModal(); }} name="isFaculty" color="primary" />}
											label="The institution has no faculty"
										/>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							defaultValue={!isFaculty ? defaultFaculty : ''}
							inputRef={register({ required: !isFaculty })}
							required
							id="faculty"
							name="faculty"
							label="Faculty name"
							fullWidth
							disabled={isFaculty}
							value={faculty}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							inputRef={register}
							defaultValue={defaultDepartment}
							id="department"
							name="department"
							label="Department name"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl required fullWidth>
							<InputLabel variant="outlined">Context of educational policies</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								refs={register({ required: true })}
								name="fk_contextOfEducationalPolicies"
								defaultValue={data.fk_contextOfEducationalPolicies ? data.fk_contextOfEducationalPolicies : contextOfEducationalPoliciesId}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select onChange={onChange} onBlur={onBlur} value={value ?? ""} name="fk_contextOfEducationalPolicies">
											{institutionStore?.institution?.contextOfEducationalPolicies?.map((institution) => (
												<MenuItem key={institution.contextOfEducationalPoliciesId} value={institution.contextOfEducationalPoliciesId}>
													{institution.contextOfEducationalPoliciesName}
												</MenuItem>
											))}
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl required fullWidth>
							<InputLabel variant="outlined">Institution ownership</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								refs={register({ required: true })}
								name="fk_institutionType"
								defaultValue={data.fk_institutionType ? data.fk_institutionType : institutionData.institutionTypeId}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select onChange={onChange} onBlur={onBlur} value={value ?? ""} name="fk_institutionType">
											{institutionStore?.institution?.institutionType?.map((institution) => (
												<MenuItem key={institution.institutionTypeId} value={institution.institutionTypeId}>
													{institution.institutionTypeName}
												</MenuItem>
											))}
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={1}>
						<FormControl fullWidth>
							<Controller
								type="checkbox"
								variant="outlined"
								control={control}
								refs={register}
								name="isReligious"
								defaultValue={isReligious}
								render={({ onChange, onBlur, value }) => {
									return (
										<FormControlLabel
											control={<Checkbox checked={isReligious} onChange={() => { setValue("isReligious", !isReligious); setIsReligious(!isReligious); }} name="isReligious" color="primary" />}
											label="Religious institution"
										/>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={5}>
						<FormControl fullWidth className="institution-form-religious-institution-dropdown">
							<InputLabel variant="outlined">Religious institution type</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								refs={register({ required: true })}
								name="fk_religiousInstitutionType"
								defaultValue={religiousTypeId ? religiousTypeId : ""}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select 
											onChange={(e: React.ChangeEvent<{value: unknown}>) => { setReligiousTypeId(e.target.value as number); setValue("fk_religiousInstitutionType", e.target.value as string); }}
											onBlur={onBlur}
											value={religiousTypeId ? religiousTypeId : ""}
											name="fk_religiousInstitutionType"
											disabled={!isReligious}
										>
											<MenuItem key="1" value="1">Public religious</MenuItem>
											<MenuItem key="2" value="2">Private religious</MenuItem>
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl required fullWidth>
							<InputLabel>Higher-education institution type</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								refs={register({ required: true })}
								name="fk_higherEducationInstitutionType"
								defaultValue={higherEducationInstitutionType ? higherEducationInstitutionType : ""}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select 
											onChange={(e: React.ChangeEvent<{value: unknown}>) => { setHigherEducationInstitutionType(e.target.value as number); setValue("fk_higherEducationInstitutionType", e.target.value as string); setIsChanged(true); }}
											onBlur={onBlur}
											value={higherEducationInstitutionType ? higherEducationInstitutionType : ""}
											name="fk_higherEducationInstitutionType"
										>
											{institutionStore?.institution?.higherEducationInstitutionType?.map((institution) => (
												<MenuItem key={institution.higherEducationInstitutionTypeId} value={institution.higherEducationInstitutionTypeId}>
													{institution.higherEducationInstitutionTypeName}
												</MenuItem>
											))}
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<Divider className="institution-form-section-divider" />
					</Grid>

					{studyPrograms.map((program, index) => (
						<Grid container style={{ marginLeft: "24px", paddingTop: "24px",}}>
							{index !== 0 ? 					
								<Grid item xs={12}>
									<Divider className="institution-form-section-divider" style={{ marginTop: "24px", marginBottom: "24px", }} />
								</Grid> 
							: null}
							<Grid container style={{ marginBottom: "12px" }}>
								<Grid item xs={6}>
									<Typography variant="h6" gutterBottom>
										Study programme
									</Typography>
								</Grid>
								<Grid item xs={6} style={{ textAlign: "right" }}>
									{index !== 0 ? 
										<Button type="button" variant="outlined" color="primary" onClick={() => handleRemoveModal(index)}>
											<HighlightOffIcon fontSize="small" style={{ marginRight: "5px", }} />
											Remove study programme
										</Button>
									: null}
								</Grid>
							</Grid>

							{program.program}
						</Grid>
					))}

					<Grid item xs={12} style={{ textAlign: "center" }}>
						<Button type="button" variant="contained" color="primary" onClick={addStudyProgram}>
							<AddCircleIcon fontSize="small" style={{ marginRight: "5px", }} />
							Add another study programme
						</Button>
					</Grid>
					
					<Grid item xs={12}>
						<Divider className="institution-form-section-divider" />
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h6">
							Location
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={8}>
										<TextField
											inputRef={register}
											id="addressRoad"
											name="addressRoad"
											label="Road name"
											defaultValue={data.addressRoad ? data.addressRoad : addressRoad}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											inputRef={register}
											id="addressHouseNumber"
											name="addressHouseNumber"
											label="House number"
											defaultValue={data.addressHouseNumber ? data.addressHouseNumber : addressHouseNumber}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											defaultValue={data.addressCountry ? data.addressCountry : addressCountry}
											inputRef={register({ required: true })}
											required
											InputProps={{
												readOnly: true,
											}}
											id="addressCountry"
											name="addressCountry"
											label="Country"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											InputProps={{
												readOnly: true,
											}}
											inputRef={register({ required: true })}
											required
											id="lat"
											name="lat"
											label="Latitude"
											fullWidth
											defaultValue={data.lat ? data.lat : lat}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											defaultValue={data.lon ? data.lon : lon}
											InputProps={{
												readOnly: true,
											}}
											inputRef={register({ required: true })}
											required
											id="lon"
											name="lon"
											label="Longitude"
											fullWidth
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Grid item xs={12}>
									<MapContainer
										center={[data.lat ? data.lat : lat, data.lon ? data.lon : lon]}
										zoom={6}
										scrollWheelZoom={false}
										className="institution-form-details-map"
									>
										<TileLayer
											attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
											url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
										/>
										<Marker position={[data.lat ? data.lat : lat, data.lon ? data.lon : lon]} />
									</MapContainer>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Grid container alignItems="center">
						<Grid item xs={9}>
							<Typography style={{ fontStyle: "italic" }}>
								{id ? "" : "Data is saved automatically, allowing you to continue working on your entry at a later date."}
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Box display="flex" p={2} flexDirection="row-reverse" style={{ paddingRight: "0px", }}>
								<Button type="submit" variant="contained" color="primary">
									Confirm
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</form>

			<Modal
				open={removeModal}
				onClose={handleRemoveModalClose}
				closeAfterTransition

				className="modal-confirmation"
			>
				<Fade in={removeModal}>
					<div className="modal-confirmation-container">
						<div className="modal-confirmation-icon-close-container">
							<IconClose
								className="modal-confirmation-icon-close"
								onClick={handleRemoveModalClose}
							/>
						</div>
						<div className="modal-confirmation-icon-container">
							<ErrorOutlineIcon color="secondary" style={{ fontSize: 50 }} />
						</div>
						<div className="modal-confirmation-text-container">
							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-title"
							>
								You are about to remove this study programme
							</Typography>

							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-description"
							>
								This study programme will be permanentely removed. This cannot be undone. Are you sure you want to remove this study programme?
							</Typography>
						</div>
						<Grid
							container
							direction="row"
							className="modal-confirmation-actions-container"
							spacing={1}
						>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-confirm-container"
							>
								<Button
									size="small"
									variant="contained"
									className="delete-button"
									onClick={removeStudyProgram}
								>
									Remove study programme
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-cancel-container"
							>
								<Button
									size="small"
									variant="outlined"
									color="primary"
									className="cancel-button"
									onClick={handleRemoveModalClose}
								>
									Cancel, keep study programme
								</Button>
							</Grid>
						</Grid>
					</div>
				</Fade>
			</Modal>

			<Modal
				open={modal}
				onClose={handleModalClose}
				closeAfterTransition

				className="modal-confirmation"
			>
				<Fade in={modal}>
					<div className="modal-confirmation-container">
						<div className="modal-confirmation-icon-close-container">
							<IconClose
								className="modal-confirmation-icon-close"
								onClick={handleModalClose}
							/>
						</div>
						<div className="modal-confirmation-icon-container">
							<ErrorOutlineIcon color="secondary" style={{ fontSize: 50 }} />
						</div>
						<div className="modal-confirmation-text-container">
							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-title"
							>
								You are about to disable a text field
							</Typography>

							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-description"
							>
								Are you absolutely sure this instituion has no faculty?
							</Typography>
						</div>
						<Grid
							container
							direction="row"
							className="modal-confirmation-actions-container"
							spacing={1}
						>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-confirm-container"
							>
								<Button
									size="small"
									variant="contained"
									className="delete-button"
									onClick={handleCheckbox}
								>
									Yes, I am sure
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-cancel-container"
							>
								<Button
									size="small"
									variant="outlined"
									color="primary"
									className="cancel-button"
									onClick={handleModalClose}
								>
									Cancel
								</Button>
							</Grid>
						</Grid>
					</div>
				</Fade>
			</Modal>

			<Snackbar open={filledModal} autoHideDuration={6000} onClose={() => { setFilledModal(false); }}>
				<Alert onClose={() => { setFilledModal(false); }} severity="error">
					Please fill all of the required fields (marked with *).
				</Alert>
			</Snackbar>

			<Snackbar open={ECTSModal} autoHideDuration={6000} onClose={() => { setECTSModal(false); }}>
				<Alert onClose={() => { setECTSModal(false); }} severity="error">
					Invalid ECTS value. Please enter a value between 30 and 1200.
				</Alert>
			</Snackbar>
		</>
	);
});
export default InstitutionFormDetails;
