import React from "react";

import { 
	Link, 
	useHistory, 
	useLocation, 
	useParams 
} from "react-router-dom";

import { observer } from "mobx-react";

import {
	ListItem, 
	ListItemIcon, 
	ListItemText,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SchoolIcon from "@material-ui/icons/School";
import LayersIcon from "@material-ui/icons/Layers";
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';

import { useAuthStore } from "../../Stores/Stores";

const Navigation = observer(() => {
	const authStore = useAuthStore();
	const history = useHistory();
	const params: any = useParams();
	const location = useLocation();
	const path = location.pathname;

	const logout = async () => {
		await authStore?.logout();
		sessionStorage.removeItem("userId");
		sessionStorage.removeItem("token");
		sessionStorage.removeItem("userRole");
		sessionStorage.removeItem("userName");
	};

	let role = sessionStorage.getItem("userRole");

	if (!sessionStorage.getItem("userId")) {
		if (path !== "/" && path !== `/institution/${params.id}` && path !== "/instructions") {
			history.push("/login");
		}
	}

	return (
		<div>
			<ListItem button component={Link} to="/">
				<ListItemIcon>
					<HomeIcon />
				</ListItemIcon>
				<ListItemText primary="Map" />
			</ListItem>
			{sessionStorage.getItem("userId") ?
				<ListItem button component={Link} to="/instructions">
					<ListItemIcon>
						<HelpIcon />
					</ListItemIcon>
					<ListItemText primary="Instructions" />
				</ListItem>
			: null}
			{role === "admin" ? 
				<ListItem button component={Link} to="/dashboard">
					<ListItemIcon>
						<DashboardIcon />
					</ListItemIcon>
					<ListItemText primary="Users" />
				</ListItem>
			: null}
			{sessionStorage.getItem("userId") ? 
				<ListItem button component={Link} to="/dashboard/institution">
					<ListItemIcon>
						<SchoolIcon />
					</ListItemIcon>
					<ListItemText primary="Institutions" />
				</ListItem>
			: null}
			{sessionStorage.getItem("userId") ? 
			<ListItem button component={Link} to="/collaborators">
				<ListItemIcon>
					<GroupIcon />
				</ListItemIcon>
				<ListItemText primary="Collaborators" />
			</ListItem>
			: null}
			{sessionStorage.getItem("userId") ? 
				<ListItem button component={Link} to="/profile">
					<ListItemIcon>
						<PersonIcon />
					</ListItemIcon>
					<ListItemText primary="Profile" />
				</ListItem>
			: null}
			{sessionStorage.getItem("userId") ? 
				<ListItem button onClick={logout} component={Link} to="/login">
					<ListItemIcon>
						<LayersIcon />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</ListItem>
				:
				<ListItem button component={Link} to="/login">
					<ListItemIcon>
						<LayersIcon />
					</ListItemIcon>
					<ListItemText primary="Login" />
				</ListItem>
			}
		</div>
	);
});
export default Navigation;
