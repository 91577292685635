import React from "react";

import { 
    FormControl, 
    InputLabel, 
    ListSubheader, 
    MenuItem, 
    Select,
} from "@material-ui/core";

function WorkArea(props) {
    const [value, setValue] = React.useState("");
    
    if (sessionStorage.getItem("hejde_profile_workarea") && !value) {
        setValue(sessionStorage.getItem("hejde_profile_workarea"));
    }

    const handleChange = (event) => {
        setValue(event.target.value);
        props.workAreaUpdate(event.target.value);
        sessionStorage.setItem("register_userWorkSubArea", event.target.value);
    }

    let key = 0;

    let selectOptions = [];
    if (props.userworkarea) {
        for (let i = 0; i < props.userworkarea.length; i++) {
            selectOptions.push(
                <ListSubheader disableSticky={true} key={"userWorkArea" + key++}>{props.userworkarea[i].userWorkAreaName}</ListSubheader>
            );
            for (let j = 0; j < props.userworkarea[i].userWorkSubArea.length; j++) {
                let subArea = props.userworkarea[i].userWorkSubArea[j];
                selectOptions.push(
                    <MenuItem value={subArea.userWorkSubAreaId} key={subArea.userWorkSubAreaId}>{subArea.userWorkSubAreaName}</MenuItem>
                );
            }
        }
    }

    return (
        <FormControl required fullWidth>
            <InputLabel variant="outlined">Work position</InputLabel>
            <Select fullWidth onChange={handleChange} value={value} variant="outlined" label="Work position">
                {selectOptions}
            </Select>
        </FormControl>
    );
}

export default WorkArea;