import React from "react";

import {
    Fab,
    Typography, 
} from "@material-ui/core";
import LanguageIcon from '@material-ui/icons/Language';

import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
} from "react-leaflet";

import "../../Style/index.css";
import { useInstitutionStore } from "../../Stores/Stores";

import EmbedHoverCard from "./EmbedHoverCard";

import axios from "axios";

function Map() {
    const institutionStore = useInstitutionStore();
    //	institutionStore initialization
	React.useEffect(() => {
		const getCodeList = async () => {
			await institutionStore?.getCodeList();
		};
		getCodeList();
	}, [institutionStore]);

    //  React state
    const [initial, setInitial] = React.useState(false);
    const [markers, setMarkers] = React.useState([]);

    //  Map position and attribution
    const position = [45.849065, 14.486211];
    const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    const URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    //  Render marker
    const marker = (marker) => {
        return (
            <Marker
                radius={6}
                weight={2}
                stroke={true}
                fillOpacity="1"
                zIndexOffset={10}
                key={marker.institutionId}
                position={[marker.lat, marker.lon]}
                onMouseOver={(e) => {
                    e.target.openPopup();
                }}
            >
                <Popup>
                    <EmbedHoverCard 
                        name={marker.nameOfTheInstitution} 
                        institutionId={marker.institutionId} 
                        isDetails={false} 
                    />
                </Popup>
            </Marker>
        );
    }

    //  GET /institution
    axios.get("https://hejde.um.si/api/institution")
        .then(res => {
            //  Check if markers are already rendered
            //  Check if institutions exist in database
            if (!initial && res.data.data.length > 0) {
                let institutionMarkers = [];

                for (let i = 0; i < res.data.data.length; i++)
                    //  Check if data contains coordinates
                    if (res.data.data[i].lat && res.data.data[i].lon && res.data.data[i].hidden == 0)
                        //  Render marker for institution
                        institutionMarkers.push(marker(res.data.data[i]));

                setMarkers(institutionMarkers);
                setInitial(true);
            }
        })
        .catch(error => {
            console.log(error);
        });

    return (
        <>
            <a href="https://hejde.um.si" target="_blank">
                <Fab variant="extended" color="primary" style={{ position: "absolute", bottom: "24px", right: "9px", zIndex: 1000, }}>
                    <LanguageIcon style={{ marginRight: "6px", }} />
                    <Typography variant="h6" style={{ fontSize: "14px", color: "#ffffff" }}>
                        Website
                    </Typography>
                </Fab>
            </a>

            <div className="main-map-container">
                <MapContainer
                    center={position}
                    minZoom={5} 
                    zoom={5}
                    maxZoom={10}
                    scrollWheelZoom={true}
                >
                    <TileLayer attribution={attribution} url={URL} />
                    {markers}
                </MapContainer>
            </div>
        </>
    );
}

export default Map;