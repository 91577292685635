import React, { useState } from "react";

import { useParams } from "react-router-dom";

import { 
	Typography,
	CssBaseline,
	makeStyles,
	Paper,
	Container,
	Grid,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";

import InstitutionProvider from "../../../../Providers/InstitutionProvider";
import StreetMapProvider from "../../../../Providers/StreetMapProvider";
import Header from "../../Header";
import InstitutionForm from "./InstitutionForm";
import InstitutionFormDetails from "./InstitutionFormDetails";
import InstitutionOpenStreet from "./InstitutitionOpenStreet";
import InstitutionStudyProgram from "./InstitutionStudyProgram";
import { useInstitutionStore } from "../../../../Stores/Stores";
import { IInstitution } from "../../../../Models/Interfaces/IInstitutions";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	layout: {
		width: "auto",
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(((600 + theme.spacing(2)) as any) * 2)]: {
			width: 600,
			marginLeft: "auto",
			marginRight: "auto",
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(((600 + theme.spacing(3)) as any) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end",
	},
	button: {
		marginTop: theme.spacing(4),
		marginLeft: theme.spacing(1),
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
}));

const InstitutionFormFragment = observer(() => {
	const classes = useStyles();
	const [step, setStep] = useState(1);
	const [formValues, setFormValues] = useState({});

	const params: any = useParams();
	const id = params.id;

	const institutionStore = useInstitutionStore();
	const [institutionDetails, setInstitutionDetails] = React.useState<IInstitution | null>(null);

	const getInstitutions = async () => {
		await institutionStore?.getInstitutions();
		updateInstitutionDetails();
	};
	getInstitutions();

	const updateInstitutionDetails = () => {
		if (institutionDetails === null) {
			if (id) {
				institutionStore?.institutions?.map((institution) => {
					if (id === institution.institutionId) {
						console.log(institution)
						setInstitutionDetails(institution);
					}
				});
			}
		}
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<Header />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Typography component="h1" variant="h4" align="center">
									{id ? "Edit institution" : "Create institution"}
								</Typography>

								Step {step} of 4

								{step === 1 && (
									<InstitutionProvider>
										<InstitutionForm setStep={setStep} formValues={formValues} institutionData={institutionDetails} id={id} setFormValues={setFormValues} />
									</InstitutionProvider>
								)}
								{step === 2 && (
									<InstitutionProvider>
										<StreetMapProvider>
											<InstitutionOpenStreet setStep={setStep} formValues={formValues} institutionData={institutionDetails} id={id} setFormValues={setFormValues} />
										</StreetMapProvider>
									</InstitutionProvider>
								)}
								{step === 3 && (
									<InstitutionProvider>
										<InstitutionStudyProgram setStep={setStep} formValues={formValues} institutionData={institutionDetails} id={id} setFormValues={setFormValues} />
									</InstitutionProvider>
								)}
								{step === 4 && (
									<InstitutionProvider>
										<StreetMapProvider>
											<InstitutionFormDetails setStep={setStep} formValues={formValues} institutionData={institutionDetails} id={id} setFormValues={setFormValues} />
										</StreetMapProvider>
									</InstitutionProvider>
								)}
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</main>
		</React.Fragment>
	);
});

export default InstitutionFormFragment;
