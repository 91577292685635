import { useLocalObservable } from "mobx-react-lite";
import React from "react";
import PropTypes from "prop-types";
import SnackbarStore, { SnackBarContext } from "../Stores/SnackbarStore";
interface ISnackBarProvider {}

const SnackBarProvider: React.FC<ISnackBarProvider> = (props) => {
	const localStore = useLocalObservable(() => new SnackbarStore());
	return <SnackBarContext.Provider value={localStore}>{props.children}</SnackBarContext.Provider>;
};

SnackBarProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
export default SnackBarProvider;
