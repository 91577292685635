import { useLocalObservable } from "mobx-react-lite";
import React from "react";
import PropTypes from "prop-types";
import UserStore, { UserStoreContext } from "../Stores/UserStore";
interface IUserProvider {}

const UserProvider: React.FC<IUserProvider> = (props) => {
	const localStore = useLocalObservable(() => new UserStore());
	return <UserStoreContext.Provider value={localStore}>{props.children}</UserStoreContext.Provider>;
};

UserProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
export default UserProvider;
