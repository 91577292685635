import React from "react";

import { Link, useParams } from "react-router-dom";

import {
    Grid,
    Typography,
    IconButton,
    Divider,
    makeStyles,
    useTheme,
    Container,
    Button, 
    Collapse,
} from "@material-ui/core";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
} from "react-leaflet";

import Header from "../Dashboard/Header";
import HoverCard from "./HoverCard";

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
    container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
    appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
    sectionContainer: {
        marginBottom: "24px !important",
    },
    pageHeader: {
        marginBottom: "12px",
    },
    backButton: {
        position: "relative !important",
        float: "right !important",
    },
    mapDivider: {
        marginLeft: "0px !important",
        marginRight: "0px !important",
        marginTop: "24px !important",
        marginBottom: "24px !important",
    }
}));

function InstitutionDetails() {
    const classes = useStyles();
    const theme = useTheme();

    const params = useParams();
    
    const [institution, setInstitution] = React.useState(null);

    const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    const URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    //  Default Collapse state
    let defaultProgrammes = [];
    //  GET /institution
    axios.get("https://hejde.um.si/api/institution")
        .then(res => {
            for (let i = 0; i < res.data.data.length; i++)
                if (res.data.data[i].institutionId == params.id)
                    for (let j = 0; j < res.data.data[i].studyPrograms.length; j++)
                        defaultProgrammes.push(false);
        })
        .catch(error => {
            console.log(error);
        });

    const [programmes, setProgrammes] = React.useState(defaultProgrammes);

    const handleCollapse = (index) => {
        let programmesCopy = [ ...programmes ];
        programmesCopy[index] = !programmesCopy[index];
        setProgrammes(programmesCopy);
    }

    //  Uppercase conversion
    const capitalizeFirstLetter = (lowercase) => {
        return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
    }

    //  Find matching institution
    if (!institution)
        //  GET /institution
        axios.get("https://hejde.um.si/api/institution")
            .then(res => {
                for (let i = 0; i < res.data.data.length; i++)
                    //  Compare IDs
                    if (res.data.data[i].institutionId == params.id) {
                        setInstitution(res.data.data[i]);
                        break;
                    }
            })
            .catch(error => {
                console.log(error);
            });

    //  
    const typeOfEnrollment = (enrollment) => {
        let output = "";

        for (let i = 0; i < enrollment.length; i++) {
            if (i != enrollment.length - 1) {
                output += enrollment[i].annualStudentEnrollmentQuotaName.toLowerCase() + ", ";
            } else {
                output += enrollment[i].annualStudentEnrollmentQuotaName.toLowerCase();
            }
        }

        return output;
    }
    
    //  Render individual study programme
    const studyProgramme = (programme) => {
        return (
            <Grid container spacing={3} className={classes.sectionContainer} key={programme.studyProgramId}>
                <Grid item xs={3}>
                    <Typography>
                        Study programme type:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.studyProgrammeType ? capitalizeFirstLetter(programme.studyProgrammeType) : null}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Study programme learning type:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.typeOfStudyProgramName ? capitalizeFirstLetter(programme.typeOfStudyProgramName) : null}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Total ECTS points:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.ECTS ? programme.ECTS : null}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Duration (in years):
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.yearsOfTheStudyProgramDuration ? programme.yearsOfTheStudyProgramDuration : null}
                    </Typography>
                </Grid>
                {programme.yearOfFoundationOfTheStudyProgram ?
                    <>
                        <Grid item xs={3}>
                            <Typography>
                                Foundation year:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                {programme.yearOfFoundationOfTheStudyProgram ? programme.yearOfFoundationOfTheStudyProgram : null}
                            </Typography>
                        </Grid>
                    </>
                : null}
                <Grid item xs={3}>
                    <Typography>
                        Degree title:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.titleOfDegree ? capitalizeFirstLetter(programme.titleOfDegree) : null}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Degree level:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.degreeLevel ? capitalizeFirstLetter(programme.degreeLevel) : null}
                    </Typography>
                </Grid>
                {programme.doubleDegree == "1" ?
                    <>
                        <Grid item xs={3}>
                            <Typography>
                                Double major:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                Yes
                            </Typography>
                        </Grid>
                    </>
                : null}
                {programme.scientificDegree == "1" ?
                    <>
                        <Grid item xs={3}>
                            <Typography>
                                Scientific stream:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                Yes
                            </Typography>
                        </Grid>
                    </>
                : null}
                <Grid item xs={3}>
                    <Typography>
                        Qualification title:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.titleOfQualification ? capitalizeFirstLetter(programme.titleOfQualification) : null}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Academic credential type:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.academicCredentialTypeName ? capitalizeFirstLetter(programme.academicCredentialTypeName) : null}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Study programme levels:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.studyProgrammeLevels ? capitalizeFirstLetter(programme.studyProgrammeLevels) : null}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Study programme degree regarding EQF/NQF:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.studyDegreeRegardingEQFNQFName ? capitalizeFirstLetter(programme.studyDegreeRegardingEQFNQFName) : null}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Type of student enrollment:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        {programme.annualstudentenrollmentquota.length > 0 ? 
                            capitalizeFirstLetter(typeOfEnrollment(programme.annualstudentenrollmentquota))
                        : null}
                    </Typography>
                </Grid>
                {programme.studyProgramWebsiteLink ?
                    <Grid item xs={3}>
                        <Typography>
                            <a 
                                href={programme.studyProgramWebsiteLink} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                Website
                            </a>
                        </Typography>
                    </Grid>
                : null}
            </Grid>
        );
    }
    
    return (
		<>
            <Header />

            <main className={classes.content}>
				<div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    {institution ?
                    <Grid container key={params.id} className={classes.instutionContainer}>
                        <Grid item xs={12}>
                            <Grid container className={classes.pageHeader}>
                                <Grid item xs={9}>
                                    <Typography variant="h5">
                                        {institution.nameOfTheInstitution}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Link to="/" className="back-to-map-link">
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.backButton}
                                        >
                                            <IconArrowBack />
                                            Back to map
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>

                            <hr />

                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="h6" style={{ marginBottom: "12px", }}>
                                        {institution.studyPrograms.length === 1 ? "Study programme" : "Study programmes"}
                                    </Typography>
                                    
                                    {institution.studyPrograms.length == 1 ?
                                        //  Single study programme
                                        studyProgramme(institution.studyPrograms[0])
                                    :
                                        //  Multiple study programmes (render Collapse)
                                        institution.studyPrograms.map((element, index) => (
                                            <>
                                                <IconButton
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        width: "95%",
                                                        paddingLeft: "0px",
                                                    }}
                                                    disableRipple={true}
                                                    disableFocusRipple={true}
                                                    onClick={() => {
                                                        handleCollapse(index);
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={11}>
                                                            <Typography  align="left">
                                                                {element.nameOfTheStudyProgram ? element.nameOfTheStudyProgram : null}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{ float: "right", }}>
                                                                {programmes[index] ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </IconButton>
                                                <Collapse in={programmes[index]} timeout={250} className="institution-details-study-programme-collapse">
                                                    {studyProgramme(institution.studyPrograms[index])}
                                                </Collapse>
                                            </>
                                        ))
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6" style={{ marginBottom: "12px", }}>
                                        Institution
                                    </Typography>

                                    <Grid container spacing={3}>
                                        {institution.faculty ? 
                                        <>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Faculty:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    {capitalizeFirstLetter(institution.faculty)}
                                                </Typography>
                                            </Grid>
                                        </>
                                        : null}
                                        {institution.department ? 
                                        <>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Department:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    {capitalizeFirstLetter(institution.department)}
                                                </Typography>
                                            </Grid>
                                        </>
                                        : null}
                                        <Grid item xs={3}>
                                            <Typography>
                                                Institution area:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {institution.academicAreaName ? capitalizeFirstLetter(institution.academicAreaName) : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Context of educational policies:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {institution.contextOfEducationalPoliciesName ? capitalizeFirstLetter(institution.contextOfEducationalPoliciesName) : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Higher-education institution type:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {institution.higherEducationInstitutionTypeName ? capitalizeFirstLetter(institution.higherEducationInstitutionTypeName) : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Institution ownership:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {institution.institutionTypeName ? capitalizeFirstLetter(institution.institutionTypeName) : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Religious institution:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {institution.religiousInstitutionTypeName ? "Yes" : "No"}
                                            </Typography>
                                        </Grid>
                                        {institution.religiousInstitutionTypeId ? 
                                        <>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Religious institution type:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    {capitalizeFirstLetter(institution.religiousInstitutionTypeName)}
                                                </Typography>
                                            </Grid>
                                        </>
                                        : null}
                                        <Grid item xs={3}>
                                            <Typography>
                                                Country:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {institution.addressCountry ? capitalizeFirstLetter(institution.addressCountry) : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Address:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        {institution.addressRoad ? capitalizeFirstLetter(institution.addressRoad) : null}
                                                        {institution.addressRoad && institution.addressHouseNumber ? " " + institution.addressHouseNumber + ", " : ", "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        {institution.addressCity && institution.addressCountry ? capitalizeFirstLetter(institution.addressCity) + ", " + capitalizeFirstLetter(institution.addressCountry) : null}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Post code:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {institution.addressPostcode ? institution.addressPostcode : null}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Divider variant="middle" className={classes.mapDivider} />

                                    <MapContainer
                                        center={[institution.lat, institution.lon]}
                                        minZoom={5} 
                                        zoom={5}
                                        maxZoom={10}
                                        scrollWheelZoom={true}
                                        style={{
                                            height: "45vh",
                                        }}
                                    >
                                        <TileLayer attribution={attribution} url={URL} />
                                        <Marker
                                            radius={6}
                                            weight={2}
                                            stroke={true}
                                            fillOpacity="1"
                                            zIndexOffset={10}
                                            key={institution.institutionId}
                                            position={[institution.lat, institution.lon]}
                                            onMouseOver={(e) => {
                                                e.target.openPopup();
                                            }}
                                        >
                                            <Popup>
                                                <HoverCard 
                                                    name={institution.nameOfTheInstitution} 
                                                    institutionId={institution.institutionId}
                                                    isDetails={true}
                                                />
                                            </Popup>
                                        </Marker>
                                    </MapContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    : null}
                </Container>
            </main>
        </>
    );
}

export default InstitutionDetails;