import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import { IOpenStreetMap } from "../Models/Interfaces/IOpenStreetMap";
import { InstitutionService } from "../Services/InstitutionService";

export default class StreetMapStore {
    openStreetMap!: IOpenStreetMap | undefined
    openStreetMaps = [] as IOpenStreetMap[]
	constructor() {
		makeObservable(this, {
			openStreetMaps: observable,
			openStreetMap: observable,
            getInstititionWithStreetMap: action,
		});
	}
    async getInstititionWithStreetMap(institutionName?:string){
    
        const response =await InstitutionService.getInstititionWithStreetMap(institutionName);
        if(response.ok){
             const data = await response.json()
             this.openStreetMaps = data as IOpenStreetMap[]
        }
        else{
            return response.status;
        }
    } 
}
export const StreetMapStoreContext = createContext<StreetMapStore | null>(null);
