import React, { useEffect, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";

import {
	CssBaseline,
	Container,
	Grid,
	Button, 
	FormControl, 
	FormControlLabel, 
	FormLabel, 
	Paper, 
	Radio, 
	RadioGroup,
	Typography,
	makeStyles,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import IconArrowBack from "@material-ui/icons/ArrowBack";

import Header from "../../Header";
import { useSnackBarStore, useUserStore } from "../../../../Stores/Stores";
import { IUser } from "../../../../Models/Interfaces/IUser";
import { Controller, useForm } from "react-hook-form";
import { EStrings } from "../../../../Models/Enums/EStrings";
import { observer } from "mobx-react-lite";
import { unzip } from "zlib";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
    pageHeader: {
        marginBottom: "12px",
    },
}));

const EditRoles = observer(() => {
	const classes = useStyles();
	const history = useHistory();

	const [user, setUser] = React.useState<any>();
	const userStore = useUserStore();
	const snackbarStore = useSnackBarStore();

	const { userId } = useParams<{ userId: string }>();

	const [roleId, setRoleId] = React.useState<any>(null);

	const { register, control, handleSubmit } = useForm({ defaultValues: { roleId: "" } });
	const [addedRole, setAddedRole] = useState(false);

	const onSubmit = (data: any) => {
		//const { roleId } = data;
		changeUserRole(userId, roleId);
	};

	const changeUserRole = async (userId: string, roleId: string) => {
		const response = await userStore?.changeUserStatus(userId, roleId);
		if (response) {
			setAddedRole(true);
			snackbarStore?.setSnackbar(EStrings.EDITED_USER_ROLE, true, EStrings.SNACKBAR_SUCCESS);
			history.go(-1);
		} else {
			snackbarStore?.setSnackbar(EStrings.EDITED_USER_ROLE, true, EStrings.SNACKBAR_ERROR);
			history.go(-1);
		}
	};

	useEffect(() => {
		const getUser = async () => {
			await userStore?.getUsers();
			updateUser();
		};
		getUser();
	}, [userStore]);

	const updateUser = () => {
		let users: any;

		users = userStore?.users.map((user) => (
			{
				"userId": user.userId,
				"roleDisplayName": user.roleDisplayName,
				"firstName": user.firstName,
				"lastName": user.lastName,
				"email": user.email,
				"registrationDate": user.registrationDate,
			}
		));

		for (let i = 0; i < users.length; i++) {
			if (users[i].userId === userId) {
				setUser(users[i]);
				
				switch (users[i].roleDisplayName) {
					case "Pending":
						setRoleId("0");
						break;
					case "Administrator":
						setRoleId("1");
						break;
					case "Editor": 
						setRoleId("2");
						break;
				}
				
				break;
			}
		}
	}

	console.log(user);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Header />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container className={classes.pageHeader}>
						<Grid item xs={9}>
							<Typography variant="h5">
								Edit role
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Link to="/dashboard" className="back-to-map-link">
								<Button
									size="medium"
									variant="outlined"
									color="primary"
									className="back-to-list-of-users-button"
								>
									<IconArrowBack />
									Back to list of users
								</Button>
							</Link>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Paper variant="outlined" style={{ padding: "24px" }}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid alignItems="center" container spacing={2}>
										<Grid item xs={12} md={12}>
											<Grid container>
												<Grid item xs={12} style={{ marginBottom: "12px" }}>
													<Typography variant="h5">
														User information
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography style={{ color: "#858585", }}>
														Name
													</Typography>
													<Typography>
														{user ? user.firstName + " " + user.lastName : ""}
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography style={{ color: "#858585", }}>
														E-mail
													</Typography>
													<Typography>
														{user ? user.email : ""}
													</Typography>													
												</Grid>												
											</Grid>
											<FormControl fullWidth required margin="normal">
												<FormLabel style={{ color: "#858585", }}>SELECT USER ROLE</FormLabel>
												<Controller
													name="roleId"
													control={control}
													as={
														<RadioGroup aria-label="roles" name="edit-user-role" style={{ display: "flex", flexDirection: "row" }}>
															<FormControlLabel ref={register} control={<Radio style={{ color: "rgba(63, 81, 181, 1)", }} />} onClick={() => setRoleId(1)} label="Administrator" name="admin" value="1" checked={roleId == 1 ? true : false} />
															<FormControlLabel ref={register} control={<Radio style={{ color: "rgba(63, 81, 181, 1)", }} />} onClick={() => setRoleId(2)} label="Editor" name="admin" value="2" checked={roleId == 2 ? true : false} />
															<FormControlLabel ref={register} control={<Radio style={{ color: "rgba(63, 81, 181, 1)", }} />} onClick={() => setRoleId(0)} label="Pending" name="admin" value="0" checked={roleId == 0 ? true : false} />
														</RadioGroup>
													}
												/>
												<Button type="submit" variant="contained" color="primary" size="small" startIcon={<SaveIcon />}>
													Submit
												</Button>
											</FormControl>
										</Grid>
									</Grid>
								</form>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</main>
		</div>
	);
});

export default EditRoles;
