import { useContext } from "react";
import { AuthStoreContext } from "./AuthStore";
import { SnackBarContext } from "./SnackbarStore";
import { UserStoreContext } from "./UserStore";
import { InstitutionStoreContext } from "./InstitutionStore";
import { StreetMapStoreContext } from "./StreetMapStore";

const stores = {
	userStore: UserStoreContext,
	authStore: AuthStoreContext,
	snackBarStore: SnackBarContext,
	institutionStore: InstitutionStoreContext,
	streetMapStore: StreetMapStoreContext,
};

export const useUserStore = () => {
	return useContext(stores.userStore);
};

export const useAuthStore = () => {
	return useContext(stores.authStore);
};

export const useSnackBarStore = () => {
	return useContext(stores.snackBarStore);
};

export const useInstitutionStore = () => {
	return useContext(stores.institutionStore);
};
export const useStreetMapStore = () => {
	return useContext(stores.streetMapStore);
};
