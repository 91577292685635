import { getToken } from "../Component/Auth/Auth";
import { EApi } from "../Models/Enums/EApi"

export const generateAPIoptions = (method: EApi, payload :any|null) => { 
    const token = getToken()
    const headers = {'content-type': 'application/json', "token": token}
    if (method!==EApi.GET){
        return {
            method,
            body:JSON.stringify(payload),
            headers
        };        
    }
    if(method===EApi.GET){
        return {method, headers}
    }
}