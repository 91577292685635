import React from "react";

import { 
	Box,
	Button,
	Typography,
	Grid,
	Snackbar, 
	Alert, 
	Divider, 
	Modal, 
	Fade, 
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconClose from "@material-ui/icons/Close";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { observer } from "mobx-react-lite";

import StudyProgram from "./StudyProgram";
import { useInstitutionStore } from "../../../../Stores/Stores";

const InstitutionStudyProgram = observer(({ setStep, formValues, institutionData, id, setFormValues }: any) => {
	const institutionStore = useInstitutionStore();
	const [fetched, setFetched] = React.useState(false);

	const [isChanged, setIsChanged] = React.useState(false);
	const [ECTSModal, setECTSModal] = React.useState(false);

	React.useEffect(() => {
		const getCodeList = async () => {
			await institutionStore?.getCodeList();
			setFetched(true);
		};
		getCodeList();
	}, [institutionStore]);

	let JSON_string: any;
	if (!id) {
		JSON_string = localStorage.getItem("studyProgrammes");
	} else {
		JSON_string = sessionStorage.getItem("studyProgrammes");
	}
	const programmes = JSON.parse(JSON_string);
	if (!programmes) {
		//	Get values from session storage
		if (formValues.studyPrograms) {
			let programmes = [];
			for (let i = 0; i < formValues.studyPrograms.length; i++) {
				programmes.push(formValues.studyPrograms[i].index);

				let isFilled = false;
				if (
					formValues.studyPrograms[i].nameOfTheStudyProgram && formValues.studyPrograms[i].studyProgramWebsiteLink 
					&& formValues.studyPrograms[i].ECTS && formValues.studyPrograms[i].degreeLevel
					&& formValues.studyPrograms[i].titleOfDegree && formValues.studyPrograms[i].titleOfQualification 
					&& formValues.studyPrograms[i].yearsAfterPrimarySecondarySchool && formValues.studyPrograms[i].yearsOfTheStudyProgramDuration
					&& formValues.studyPrograms[i].fk_annualStudentEnrollmentQuota && formValues.studyPrograms[i].fk_studyDegreeRegardingEQFNQF
					&& formValues.studyPrograms[i].fk_typeOfStudyProgram
					&& formValues.studyPrograms[i].studyProgrammeType && formValues.studyPrograms[i].studyProgrammeLevels
					&& formValues.studyPrograms[i].academicCredentialTypeId
				)
					isFilled = true;

				const studyProgram = {
					nameOfTheStudyProgram: formValues.studyPrograms[i].nameOfTheStudyProgram,
					studyProgramWebsiteLink: formValues.studyPrograms[i].studyProgramWebsiteLink,
					yearOfFoundationOfTheStudyProgram: formValues.studyPrograms[i].yearOfFoundationOfTheStudyProgram,
					studyProgrammeType: formValues.studyPrograms[i].studyProgrammeType,
					ECTS: formValues.studyPrograms[i].ECTS,
					doubleDegree: formValues.studyPrograms[i].doubleDegree,
					scientificDegree: formValues.studyPrograms[i].scientificDegree,
					titleOfDegree: formValues.studyPrograms[i].titleOfDegree,
					degreeLevel: formValues.studyPrograms[i].degreeLevel,
					titleOfQualification: formValues.studyPrograms[i].titleOfQualification,
					studyProgrammeLevels: formValues.studyPrograms[i].studyProgrammeLevels,
					yearsAfterPrimarySecondarySchool: formValues.studyPrograms[i].yearsAfterPrimarySecondarySchool,
					yearsOfTheStudyProgramDuration: formValues.studyPrograms[i].yearsOfTheStudyProgramDuration,
					fk_annualStudentEnrollmentQuota: formValues.studyPrograms[i].fk_annualStudentEnrollmentQuota,
					fk_studyDegreeRegardingEQFNQF: formValues.studyPrograms[i].fk_studyDegreeRegardingEQFNQF,
					fk_typeOfStudyProgram: formValues.studyPrograms[i].fk_typeOfStudyProgram,
					fk_academicCredentialType: formValues.studyPrograms[i].academicCredentialTypeId,
					isFilled: isFilled,
					index: formValues.studyPrograms[i].index,
				};
		
				if (!id) {
					localStorage.setItem(`studyProgram${formValues.studyPrograms[i].index}`, JSON.stringify(studyProgram));
				} else {
					sessionStorage.setItem(`studyProgram${formValues.studyPrograms[i].index}`, JSON.stringify(studyProgram));
				}
			}

			if (!id) {
				localStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			} else {
				sessionStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			}
		//	Render data from database	
		} else if (institutionData && institutionData.studyPrograms) {
			let programmes = [];
			for (let i = 0; i < institutionData.studyPrograms.length; i++) {
				programmes.push(i);

				let isFilled = false;
				if (
					institutionData.studyPrograms[i].nameOfTheStudyProgram && institutionData.studyPrograms[i].studyProgramWebsiteLink 
					&& institutionData.studyPrograms[i].ECTS && institutionData.studyPrograms[i].degreeLevel
					&& institutionData.studyPrograms[i].titleOfDegree && institutionData.studyPrograms[i].titleOfQualification 
					&& institutionData.studyPrograms[i].yearsAfterPrimarySecondarySchool && institutionData.studyPrograms[i].yearsOfTheStudyProgramDuration
					&& institutionData.studyPrograms[i].annualstudentenrollmentquota && institutionData.studyPrograms[i].studyDegreeRegardingEQFNQFId
					&& institutionData.studyPrograms[i].typeOfStudyProgramId
					&& institutionData.studyPrograms[i].studyProgrammeType && institutionData.studyPrograms[i].studyProgrammeLevels
					&& institutionData.studyPrograms[i].academicCredentialTypeId
				)
					isFilled = true;

				let annualstudentenrollmentquota: string[] = [];
				for (let j = 0; j < institutionData.studyPrograms[i].annualstudentenrollmentquota.length; j++) {
					annualstudentenrollmentquota.push(institutionData.studyPrograms[i].annualstudentenrollmentquota[j].annualStudentEnrollmentQuotaId);
				}
		
				const studyProgram = {
					nameOfTheStudyProgram: institutionData.studyPrograms[i].nameOfTheStudyProgram,
					studyProgramWebsiteLink: institutionData.studyPrograms[i].studyProgramWebsiteLink,
					yearOfFoundationOfTheStudyProgram: institutionData.studyPrograms[i].yearOfFoundationOfTheStudyProgram,
					studyProgrammeType: institutionData.studyPrograms[i].studyProgrammeType,
					ECTS: institutionData.studyPrograms[i].ECTS,
					doubleDegree: institutionData.studyPrograms[i].doubleDegree == 1 ? true : false,
					scientificDegree: institutionData.studyPrograms[i].scientificDegree == 1 ? true : false,
					titleOfDegree: institutionData.studyPrograms[i].titleOfDegree,
					degreeLevel: institutionData.studyPrograms[i].degreeLevel,
					titleOfQualification: institutionData.studyPrograms[i].titleOfQualification,
					studyProgrammeLevels: institutionData.studyPrograms[i].studyProgrammeLevels,
					yearsAfterPrimarySecondarySchool: institutionData.studyPrograms[i].yearsAfterPrimarySecondarySchool,
					yearsOfTheStudyProgramDuration: institutionData.studyPrograms[i].yearsOfTheStudyProgramDuration,
					fk_annualStudentEnrollmentQuota: annualstudentenrollmentquota,
					fk_studyDegreeRegardingEQFNQF: institutionData.studyPrograms[i].studyDegreeRegardingEQFNQFId,
					fk_typeOfStudyProgram: institutionData.studyPrograms[i].typeOfStudyProgramId,
					fk_academicCredentialType: institutionData.studyPrograms[i].academicCredentialTypeId,
					isFilled: isFilled,
					index: i,
					isFoundationYearDisabled: institutionData.studyPrograms[i].yearOfFoundationOfTheStudyProgram ? false : true,
				};

				if (!id) {
					localStorage.setItem(`studyProgram${i}`, JSON.stringify(studyProgram));
				} else {
					sessionStorage.setItem(`studyProgram${i}`, JSON.stringify(studyProgram));
				}
			}

			if (!id) {
				localStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			} else {
				sessionStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			}
		}
	}

	let defaultIndex, defaultStudyPrograms = [];
	let studyProgrammesStringified: any 
	if (!id) {
		studyProgrammesStringified = localStorage.getItem("studyProgrammes");
	} else {
		studyProgrammesStringified = sessionStorage.getItem("studyProgrammes");
	}
	const studyProgrammes = JSON.parse(studyProgrammesStringified);

	if (studyProgrammes) {
		defaultIndex = studyProgrammes.length;
		for (let i = 0; i < studyProgrammes.length; i++) {
			defaultStudyPrograms.push({
				program: <StudyProgram index={studyProgrammes[i]} id={id} />,
				index: studyProgrammes[i],
			});
		}
	} else {
		const studyProgram = {
			nameOfTheStudyProgram: "",
			studyProgramWebsiteLink: "",
			yearOfFoundationOfTheStudyProgram: "",
			studyProgrammeType: "",
			ECTS: "",
			doubleDegree: false,
			scientificDegree: false,
			titleOfDegree: "",
			degreeLevel: "",
			titleOfQualification: "",
			studyProgrammeLevels: "",
			yearsAfterPrimarySecondarySchool: "",
			yearsOfTheStudyProgramDuration: "",
			fk_annualStudentEnrollmentQuota: [],
			fk_studyDegreeRegardingEQFNQF: "",
			fk_typeOfStudyProgram: "",
			fk_academicCredentialType: "",
			isFilled: false,
			index: 0,
			isFoundationYearDisabled: false,
		};

		if (!id) {
			localStorage.setItem(`studyProgram0`, JSON.stringify(studyProgram));
			localStorage.setItem("studyProgrammes", JSON.stringify([0]));
		} else {
			sessionStorage.setItem(`studyProgram0`, JSON.stringify(studyProgram));
			sessionStorage.setItem("studyProgrammes", JSON.stringify([0]));
		}

		defaultStudyPrograms.push({
			program: <StudyProgram index={0} />, 
			index: 0,
		});
	}

	const [studyPrograms, setStudyPrograms] = React.useState<any[]>(defaultStudyPrograms);
	const [index, setIndex] = React.useState(defaultStudyPrograms.length ? defaultStudyPrograms.length : 1);
	
	const [filledModal, setFilledModal] = React.useState(false);
	const [removeModal, setRemoveModal] = React.useState(false);
	const [removeIndex, setRemoveIndex] = React.useState<any>(null);

	const addStudyProgram = () => {
		const programs = [...studyPrograms];

		programs.push({
			program: <StudyProgram index={index} />,
			index: index,
		});

		const studyProgram = {
			nameOfTheStudyProgram: "",
			studyProgramWebsiteLink: "",
			yearOfFoundationOfTheStudyProgram: "",
			studyProgrammeType: "",
			ECTS: "",
			doubleDegree: false,
			scientificDegree: false,
			titleOfDegree: "",
			degreeLevel: "",
			qualification: "",
			titleOfQualification: "",
			studyProgrammeLevels: "",
			yearsAfterPrimarySecondarySchool: "",
			yearsOfTheStudyProgramDuration: "",
			fk_annualStudentEnrollmentQuota: [],
			fk_studyDegreeRegardingEQFNQF: "",
			fk_typeOfStudyProgram: "",
			fk_academicCredentialType: "",
			isFilled: false,
			index: index,
			isFoundationYearDisabled: false,
		};

		let programmes_string: any;
		if (!id) {
			programmes_string = localStorage.getItem("studyProgrammes");
		} else {
			programmes_string = sessionStorage.getItem("studyProgrammes");
		}

		let programmes: any[] = JSON.parse(programmes_string);
		programmes.push(index);
		
		if (!id) {
			localStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			localStorage.setItem(`studyProgram${index}`, JSON.stringify(studyProgram));
		} else {
			sessionStorage.setItem("studyProgrammes", JSON.stringify(programmes));
			sessionStorage.setItem(`studyProgram${index}`, JSON.stringify(studyProgram));
		}

		let phIndex = index + 1;
		setIndex(phIndex++);

		setStudyPrograms(programs);
		setIsChanged(true);
	}

	const removeStudyProgram = () => {
		const programs = [...studyPrograms];
		if (removeIndex !== 0) {
			const currentIndex = studyPrograms[removeIndex].index;
			
			let programmes_string: any;
			if (!id) {
				programmes_string = localStorage.getItem("studyProgrammes");
			} else {
				programmes_string = sessionStorage.getItem("studyProgrammes");
			}

			let programmes: any[] = JSON.parse(programmes_string);
			
			for (let i = 0; i < programmes.length; i++) {
				if (programmes[i] === currentIndex) {
					programmes.splice(i, 1);
					break;
				}
			}

			if (!id) {
				localStorage.setItem("studyProgrammes", JSON.stringify(programmes));
				localStorage.removeItem(`studyProgram${currentIndex}`);
			} else {
				sessionStorage.setItem("studyProgrammes", JSON.stringify(programmes));
				sessionStorage.removeItem(`studyProgram${currentIndex}`);
			}

			programs.splice(removeIndex, 1);
		}

		setStudyPrograms(programs);
		setRemoveIndex(null);
		setRemoveModal(false);
		setIsChanged(true);
	}

	const submitData = () => {		
		const programs = [...studyPrograms];

		let isFilled = true, isValid = true;
		for (let i = 0; i < programs.length; i++) {
			let JSON_string: any;
			if (!id) {
				JSON_string = localStorage.getItem(`studyProgram${programs[i].index}`) ? localStorage.getItem(`studyProgram${programs[i].index}`) : "";
			} else {
				JSON_string = sessionStorage.getItem(`studyProgram${programs[i].index}`) ? sessionStorage.getItem(`studyProgram${programs[i].index}`) : "";
			}

			if (JSON_string !== "") {
				const JSON_object: any = JSON.parse(JSON_string);
	
				if (!JSON_object.isFilled) {
					isFilled = false;
					setFilledModal(true);
					break;
				}

				if (JSON_object.ECTS < 30 || JSON_object.ECTS > 1200) {
					isValid = false;
					setECTSModal(true);
				}
			}
		}

		let data: any = [];
		if (isFilled && isValid) {
			for (let i = 0; i < programs.length; i++) {
				let JSON_string: any;
				if (!id) {
					JSON_string = localStorage.getItem(`studyProgram${programs[i].index}`) ? localStorage.getItem(`studyProgram${programs[i].index}`) : "";
				} else {
					JSON_string = sessionStorage.getItem(`studyProgram${programs[i].index}`) ? localStorage.getItem(`studyProgram${programs[i].index}`) : "";
				}
				if (JSON_string !== "") {
					const JSON_object: any = JSON.parse(JSON_string);
					data.push(JSON_object);
				}
			}

			const finalStudyPrograms = { studyPrograms: data };
			setFormValues({ ...formValues, ...finalStudyPrograms });
			institutionStore?.setInstitution(data);
			setStep(4);
		}
	}

	const handleRemoveModal = (index: any) => {
		setRemoveModal(true);
		setRemoveIndex(index);
	}
	
	const handleRemoveModalClose = () => {
		setRemoveModal(false);
	}

    return (
        <>
			<Button
				style={{
					marginBottom: "4px",
				}}
				onClick={() => {
					setStep(2);
				}}
			>
				Back
			</Button>

			{studyPrograms.map((program, index) => (
				<>
					{index !== 0 ? 					
						<Grid item xs={12}>
							<Divider style={{ marginTop: "24px", marginBottom: "24px", }} />
						</Grid> 
					: null}					
					<Grid container style={{ marginBottom: "18px" }}>
						<Grid item xs={6}>
							<Typography variant="h6" gutterBottom>
								Study programme information
							</Typography>
						</Grid>
						<Grid item xs={6} style={{ textAlign: "right" }}>
							{index !== 0 ? 
								<Button type="button" variant="outlined" color="primary" onClick={() => handleRemoveModal(index)}>
									<HighlightOffIcon fontSize="small" style={{ marginRight: "5px", }} />
									Remove study programme
								</Button>
							: null}
						</Grid>
					</Grid>

					{program.program}
				</>
			))}

			<Grid item xs={12} style={{ textAlign: "center", marginTop: "24px", }}>
				<Button type="button" variant="contained" color="primary" onClick={addStudyProgram}>
					<AddCircleIcon fontSize="small" style={{ marginRight: "5px", }} />
					Add another study programme
				</Button>
			</Grid>

			<Grid item xs={12}>
				<Grid container alignItems="center">
					<Grid item xs={9}>
						<Typography style={{ fontStyle: "italic" }}>
							{id ? "" : "Data is saved automatically, allowing you to continue working on your entry at a later date."}
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Box display="flex" p={2} flexDirection="row-reverse" style={{ paddingRight: "0px", }}>
							<Button type="button" variant="contained" color="primary" onClick={submitData}>
								Next
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Grid>

			<Modal
				open={removeModal}
				onClose={handleRemoveModalClose}
				closeAfterTransition

				className="modal-confirmation"
			>
				<Fade in={removeModal}>
					<div className="modal-confirmation-container">
						<div className="modal-confirmation-icon-close-container">
							<IconClose
								className="modal-confirmation-icon-close"
								onClick={handleRemoveModalClose}
							/>
						</div>
						<div className="modal-confirmation-icon-container">
							<ErrorOutlineIcon color="secondary" style={{ fontSize: 50 }} />
						</div>
						<div className="modal-confirmation-text-container">
							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-title"
							>
								You are about to remove this study programme
							</Typography>

							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-description"
							>
								This study programme will be permanentely removed. This cannot be undone. Are you sure you want to remove this study programme?
							</Typography>
						</div>
						<Grid
							container
							direction="row"
							className="modal-confirmation-actions-container"
							spacing={1}
						>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-confirm-container"
							>
								<Button
									size="small"
									variant="contained"
									className="delete-button"
									onClick={removeStudyProgram}
								>
									Remove study programme
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-cancel-container"
							>
								<Button
									size="small"
									variant="outlined"
									color="primary"
									className="cancel-button"
									onClick={handleRemoveModalClose}
								>
									Cancel, keep study programme
								</Button>
							</Grid>
						</Grid>
					</div>
				</Fade>
			</Modal>

			<Snackbar open={filledModal} autoHideDuration={6000} onClose={() => { setFilledModal(false); }}>
				<Alert onClose={() => { setFilledModal(false); }} severity="error">
					Please fill all of the required fields (marked with *).
				</Alert>
			</Snackbar>

			<Snackbar open={ECTSModal} autoHideDuration={6000} onClose={() => { setECTSModal(false); }}>
				<Alert onClose={() => { setECTSModal(false); }} severity="error">
					Invalid ECTS value. Please enter a value between 30 and 1200.
				</Alert>
			</Snackbar>
        </>
    );
})

export default InstitutionStudyProgram;
