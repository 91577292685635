import React from "react";

import { Link } from "react-router-dom";

import {
	Button, 
	Grid, 
	Typography, 
	Modal,
	Fade,
	makeStyles, 
} from "@material-ui/core";
import { 
	DataGrid,
	GridColDef,
} from "@material-ui/data-grid";
import EditIcon from "@material-ui/icons/Edit";
import IconClose from "@material-ui/icons/Close";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { observer } from "mobx-react-lite";

import Header from "../Header";
import { useInstitutionStore } from "../../../Stores/Stores";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	dataGrid: {
		marginLeft: "24px",
		marginRight: "24px",
	},
	buttonIcon: {
		marginRight: "5px",
		marginLeft: "-5px",
	}
}));

const Institution = observer(() => {
	const institutionStore = useInstitutionStore();
	const classes = useStyles();
	
	//	React state
	const [rows, setRows] = React.useState([]);
	const [removeId, setRemoveId] = React.useState<string | null>(null);
	const [removeModal, setRemoveModal] = React.useState(false);
	const [institutions, setInstitutions] = React.useState([]);
	const [initial, setInitial] = React.useState<any>(false);

	//	User handling
	const user = sessionStorage.getItem("userId");
	const isAdmin = sessionStorage.getItem("userRole");
	const isEditor = isAdmin === "admin" || isAdmin === "editor" ? true : false;

	//  GET /institution
	axios.get("https://hejde.um.si/api/institution")
		.then(res => {
			//  Check if institutions are already rendered
			//  Check if institutions exist in database
			if (!initial && res.data.data.length > 0) {
				//  Pulled from API
				let institutionList: any = [];
				let phRows: any = [];

				for (let i = 0; i < res.data.data.length; i++)
					institutionList.push(res.data.data[i]);

				//	Empty result check
				if (institutionList.length > 0) {
					setInstitutions(institutionList);

					institutionList.map((institution: any) => {
						phRows.push(
							{
								id: institution.institutionId,
								nameOfTheInstitution: institution.nameOfTheInstitution,
								type: institution.institutionTypeName,
								faculty: institution.faculty,
								institutionAddedByUserId: institution.institutionAddedByUserId,
								hidden: institution.hidden,
							}
						)
					});

					setRows(phRows);
					setInitial(true);
				}

			}
		})
		.catch(error => {
			console.log(error);
		});

	//	sessionStorage clean-up
	const studyProgrammesStringified: any = sessionStorage.getItem("studyProgrammes");
	const studyProgrammes = JSON.parse(studyProgrammesStringified);
	if (studyProgrammes) {
		for (let i = 0; i < studyProgrammes.length; i++) {
			sessionStorage.removeItem(`studyProgram${studyProgrammes[i]}`);
		}

		sessionStorage.removeItem("studyProgrammes");
	}
	
	const handleRemoveModal = (id: string) => {
		setRemoveModal(true);
		setRemoveId(id);
	}
	
	const handleRemoveModalClose = () => {
		setRemoveModal(false);
	}
	
	//	Remove institution + site refresh
	const removeInstitution = async () => {
		const id = removeId;
			
		if (id)
			await institutionStore?.removeInstitution(id);

		setRemoveModal(false);
		window.location.reload();
	}

	const updateList = () => {
			//  GET /institution
		axios.get("https://hejde.um.si/api/institution")
			.then(res => {
				//  Check if institutions are already rendered
				//  Check if institutions exist in database
				if (!initial && res.data.data.length > 0) {
					//  Pulled from API
					let institutionList: any = [];
					let phRows: any = [];

					for (let i = 0; i < res.data.data.length; i++)
						institutionList.push(res.data.data[i]);

					//	Empty result check
					if (institutionList.length > 0) {
						setInstitutions(institutionList);

						institutionList.map((institution: any) => {
							phRows.push(
								{
									id: institution.institutionId,
									nameOfTheInstitution: institution.nameOfTheInstitution,
									type: institution.institutionTypeName,
									faculty: institution.faculty,
									institutionAddedByUserId: institution.institutionAddedByUserId,
									hidden: institution.hidden,
								}
							)
						});

						setRows(phRows);
						setInitial(true);
					}

				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	//	Show institution
	const show = (id: any) => {
		const params = { "institutionId": id, "hidden": 0 };
		const config = {
			headers: {
				token: sessionStorage.getItem("token")
			}
		}
		axios.post("https://hejde.um.si/api/institutionVisibility", params, config).then(res => {
			window.location.reload();
		}).catch((e) => {
			console.log(e);
		});
	}

	//	Hide institution
	const hide = (id: any) => {
		const params = { "institutionId": id, "hidden": 1 };
		const config = {
			headers: {
				token: sessionStorage.getItem("token")
			}
		}
		axios.post("https://hejde.um.si/api/institutionVisibility", params, config).then(res => {
			window.location.reload();
		}).catch((e) => {
			console.log(e);
		});
	}
	
	//	Column settings
	const columns: GridColDef[] = [
		{
			field: "status", 
			headerName: " ", 
			width: 50,
			renderCell: params => (
				<>
					{params.row.hidden == 0 ? 
						<FiberManualRecordIcon style={{ color: "#57BB7A" }} />
					:
						<FiberManualRecordIcon style={{ color: "#E75A4D" }} />
					}
				</>
			)
		},
		{ 
			field: "nameOfTheInstitution", 
			headerName: "Name of the institution", 
			width: 500,
			renderCell: params => (
				<Link to={`/institution/${params.row.id}`}>{params.row.nameOfTheInstitution}</Link>
			)
		},
		{ 
			field: "type", 
			headerName: "Type", 
			width: 100,
		},
		{ 
			field: "faculty", 
			headerName: "Faculty", 
			width: 250,
		},
		{
			field: "visibility", 
			headerName: "Visibility", 
			width: isAdmin === "admin" || user === "71" ? 125 : 0,
			renderCell: params => (
				<>
					{isAdmin === "admin" || user === "71" ?
						params.row.hidden == 0 ? 
							<>
								<Button onClick={() => hide(params.row.id)}>
									<VisibilityOffIcon className={classes.buttonIcon} />
									Hide
								</Button>
							</>
							:
							<>
								<Button onClick={() => show(params.row.id)}>
									<VisibilityIcon className={classes.buttonIcon} />
									Show
								</Button>
							</>
					: null}
				</>
			)
		},
		{
			field: "editInstitutionData",
			headerName: " ",
			width: 125,
			renderCell: params => (
				<>
					{isAdmin === "admin" || user === params.row.institutionAddedByUserId ?
						<Button variant="outlined" component={Link} to={`/dashboard/institution/edit-institution/${params.row.id}`}>
							<EditIcon className={classes.buttonIcon} />
							Edit
						</Button>
					: null}
				</>
			)
		},
		{
			field: "removeInstitution",
			headerName: " ",
			width: 150,
			renderCell: params => (
				<>
					{isAdmin === "admin" || user === params.row.institutionAddedByUserId ?
						<Button variant="outlined" onClick={() => handleRemoveModal(params.row.id)}>
							<HighlightOffIcon className={classes.buttonIcon} />
							Remove
						</Button>
					: null}
				</>
			)
		},
		{
			field: "id",
			headerName: "ID",
			hide: true,
			width: 0,
		},
	];

	return (
		<>
			<Header />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Grid container className="add-institution-button-container">
					<Grid item xs={6} className="institution-list-header">
						<Typography
							variant="h4"
							style={{
								fontSize: "25px",
								paddingTop: "3px",
							}}
						>
							Institutions
						</Typography>
					</Grid>
					{isEditor ? 
						<Grid item xs={6}>
							<Button 
								color="primary" 
								variant="contained" 
								className="add-institution-button"
								component={Link} 
								to="/dashboard/institution/create-institution"
							>
								Add institution
							</Button>
						</Grid>
					: null}
				</Grid>
				<DataGrid
					rows={rows ? rows : []}
					columns={columns}
					pageSize={10}
					className={classes.dataGrid}
					autoHeight={true}
					disableSelectionOnClick
				/>
			</main>
			<Modal
				open={removeModal}
				onClose={handleRemoveModalClose}
				closeAfterTransition

				className="modal-confirmation"
			>
				<Fade in={removeModal}>
					<div className="modal-confirmation-container">
						<div className="modal-confirmation-icon-close-container">
							<IconClose
								className="modal-confirmation-icon-close"
								onClick={handleRemoveModalClose}
							/>
						</div>
						<div className="modal-confirmation-icon-container">
							<ErrorOutlineIcon color="secondary" style={{ fontSize: 50 }} />
						</div>
						<div className="modal-confirmation-text-container">
							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-title"
							>
								You are about to remove this institution
							</Typography>

							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-description"
							>
								This institution will be permanentely removed. This cannot be undone. Are you sure you want to remove this institution?
							</Typography>
						</div>
						<Grid
							container
							direction="row"
							className="modal-confirmation-actions-container"
							spacing={1}
						>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-confirm-container"
							>
								<Button
									size="small"
									variant="contained"
									className="delete-button"
									onClick={removeInstitution}
								>
									Remove institution
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-cancel-container"
							>
								<Button
									size="small"
									variant="outlined"
									color="primary"
									className="cancel-button"
									onClick={handleRemoveModalClose}
								>
									Cancel, keep institution
								</Button>
							</Grid>
						</Grid>
					</div>
				</Fade>
			</Modal>
		</>
	);
});

export default Institution;
