import React, { useEffect, useState } from "react";

import { 
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	TextField,
	FormControlLabel,
	Checkbox, 
	Grid, 
	Snackbar, 
	Alert, 
	Modal, 
	Fade, 
} from "@material-ui/core";
import IconClose from "@material-ui/icons/Close";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Controller, useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";

import { useInstitutionStore } from "../../../../Stores/Stores";
import { IInstitution } from "../../../../Models/Interfaces/IInstitutions";

const InstitutionForm = observer(({ setStep, formValues, institutionData, id, setFormValues }: any) => {	
	const institutionStore = useInstitutionStore();
	const [fetched, setFetched] = useState(false);
	const { register, control, handleSubmit, setValue } = useForm<IInstitution>();

	//	institutionStore initialization
	useEffect(() => {
		const getCodeList = async () => {
			await institutionStore?.getCodeList();
			setFetched(true);
		};
		getCodeList();
	}, [institutionStore]);

	//	Save data and progress to step 2
	const onSubmit = (data: IInstitution) => {
		console.log({ ...data });
		formValues = { ...formValues, ...data };
		setFormValues(formValues);
		institutionStore?.setInstitution(data);
		setStep(2);
	};
	
	let religiousInstitutionTypeId;
	if (formValues.isReligious !== undefined) {
		religiousInstitutionTypeId = formValues.isReligious;
	} else {
		if (institutionData) {
			if (institutionData.religiousInstitutionTypeId) {
				religiousInstitutionTypeId = true;
			} else {
				religiousInstitutionTypeId = false;
			}
		} else {
			religiousInstitutionTypeId = false;
		}
	}

	//	Recovery from localStorage
	let local: any, recovery: any;
	if (!id) {
		local = localStorage.getItem('hejde_institution_details');
		recovery = JSON.parse(local);
	}

	//	React state
	const [data, setData] = React.useState<any>(null);
	const [nameOfTheInstitution, setInstitutionName] = React.useState(
		formValues.nameOfTheInstitution ? formValues.nameOfTheInstitution :
		recovery ? recovery.nameOfTheInstitution : ""
	);
	const [academicAreaId, setAcademicArea] = React.useState<Number | String | null>(null);
	const [faculty, setFaculty] = React.useState(
		recovery ? recovery.faculty: ""
	);
	const [department, setDepartment] = React.useState(
		formValues.department ? formValues.department : 
		recovery ? recovery.department : ""
	);
	const [contextOfEducationalPoliciesId, setContextOfEducationalPoliciesId] = React.useState<Number | String | null>(null);
	const [institutionTypeId, setInstitutionTypeId] = React.useState<Number | String | null>(
		formValues.fk_institutionType ? formValues.fk_institutionType :
		recovery ? recovery.institutionTypeId : ""
	);
	const [religiousTypeId, setReligiousTypeId] = React.useState<Number | String | null>(
		formValues.fk_religiousInstitutionType ? formValues.fk_religiousInstitutionType :
		recovery ? recovery.religiousTypeId : ""
	);
	const [higherEducationInstitutionType, setHigherEducationInstitutionType] = React.useState<Number | String | null>(
		formValues.fk_higherEducationInstitutionType ? formValues.fk_higherEducationInstitutionType :
		recovery ? recovery.higherEducationInstitutionType : ""
	);

	const [isFaculty, setIsFaculty] = React.useState(false);
	const [modal, setModal] = React.useState(false);

	const [isReligious, setIsReligious] = React.useState(
		religiousInstitutionTypeId ? true :
		recovery ? recovery.religiousInstitutionTypeId : false
	);
	const [isChanged, setIsChanged] = React.useState(false);
	const [initialized, setInitialized] = React.useState(false);

	const [yearModal, setYearModal] = React.useState(false);

	//	Institution data from database
	React.useEffect(() => {
		if (institutionData)
			setData(institutionData);
	}, [institutionData]);

	//	Update isFaculty checkbox
	React.useEffect(() => {
		if (data)
			if (!data.faculty && !faculty)
				setIsFaculty(true);
	}, [faculty, data]);

	// Save draft upon update
	React.useEffect(() => {
		//	Initialization
		let institution;
		if (recovery) {
			institution = recovery;
		} else {
			institution = {
				nameOfTheInstitution: "",
				academicAreaId: "",
				faculty: "",
				department: "",
				contextOfEducationalPoliciesId: "",
				institutionTypeId: "",
				religiousTypeId: "",
				higherEducationInstitutionType: "",
				isFaculty: false,
				isReligious: false,
			};
		}

		//	Update values
		if (nameOfTheInstitution)
			institution.nameOfTheInstitution = nameOfTheInstitution;
		if (academicAreaId) {
			setValue("fk_academicArea", academicAreaId);
			institution.academicAreaId = academicAreaId;
		}
		if (faculty)
			institution.faculty = faculty;
		if (department)
			institution.department = department;
		if (contextOfEducationalPoliciesId) {
			institution.contextOfEducationalPoliciesId = contextOfEducationalPoliciesId;
			setValue("fk_contextOfEducationalPolicies", contextOfEducationalPoliciesId);
		}
		if (institutionTypeId) {
			institution.institutionTypeId = institutionTypeId;
			setValue("fk_institutionType", institutionTypeId);
		}
		if (religiousTypeId) {
			institution.religiousTypeId = religiousTypeId;
			setValue("fk_religiousInstitutionType", religiousTypeId);
		}
		if (higherEducationInstitutionType) {
			institution.higherEducationInstitutionType = higherEducationInstitutionType;
			setValue("fk_higherEducationInstitutionType", higherEducationInstitutionType);
		}
		
		if (recovery) {
			if (isFaculty != recovery.isFaculty)
				institution.isFaculty = isFaculty;
			if (isReligious != recovery.isReligious)
				institution.isReligious = isReligious;
		}

		//	Save draft to localStorage
		if (!id)
			localStorage.setItem('hejde_institution_details', JSON.stringify(institution));
	}, [nameOfTheInstitution, academicAreaId, faculty, department, contextOfEducationalPoliciesId, institutionTypeId, religiousTypeId, higherEducationInstitutionType, isFaculty, isReligious]);

	//	Change value
	//	Pull from fromValues if exists
	//	Else pull from localStorage if exists
	if (!academicAreaId)
		if (formValues.fk_academicArea) {
			setAcademicArea(formValues.fk_academicArea);
		} else if (recovery) {
			if (recovery.academicAreaId != "")
				setAcademicArea(recovery.academicAreaId);
		}

	//	Change value
	//	Pull from fromValues if exists
	//	Else pull from localStorage if exists
	if (!contextOfEducationalPoliciesId)
		if (formValues.fk_contextOfEducationalPolicies) {
			setContextOfEducationalPoliciesId(formValues.fk_contextOfEducationalPolicies);
		} else if (recovery) {
			if (recovery.contextOfEducationalPoliciesId != "")
				setContextOfEducationalPoliciesId(recovery.contextOfEducationalPoliciesId);
		}

	//	Change value
	//	Pull from fromValues if exists			
	//	Else pull from localStorage if exists
	if (!institutionTypeId)
		if (formValues.fk_institutionType) {
			setInstitutionTypeId(formValues.fk_institutionType);
		} else if (recovery) {
			if (recovery.institutionTypeId != "")
				setInstitutionTypeId(recovery.institutionTypeId);
		}

	if (!higherEducationInstitutionType)
		if (formValues.fk_higherEducationInstitutionType) {
			setHigherEducationInstitutionType(formValues.fk_higherEducationInstitutionType);
		} else if (recovery) {
			if (recovery.higherEducationInstitutionType != "")
				setHigherEducationInstitutionType(recovery.higherEducationInstitutionType);
		}
	
	if (institutionData && !isChanged) {
		if (!nameOfTheInstitution)
			setInstitutionName(institutionData.nameOfTheInstitution);

		if (!academicAreaId)
			setAcademicArea(institutionData.academicAreaId);

		if (!faculty)
			if (institutionData.faculty) {
				setFaculty(institutionData.faculty);
			}

		if (!department)
			if (institutionData.department)
				setDepartment(institutionData.department);

		if (!institutionTypeId)
			setInstitutionTypeId(institutionData.institutionTypeId);

		if (!religiousTypeId && !formValues.fk_religiousInstitutionType)
			if (institutionData.religiousInstitutionTypeId) {
				setReligiousTypeId(institutionData.religiousInstitutionTypeId);
				if (formValues.isReligious === undefined) {
					setIsReligious(true);
					setValue("isReligious", true);
				}
			}

		if (!higherEducationInstitutionType)
			if (institutionData.higherEducationInstitutionTypeId)
				setHigherEducationInstitutionType(institutionData.higherEducationInstitutionTypeId);

		if (!contextOfEducationalPoliciesId)
			switch (institutionData.contextOfEducationalPoliciesName) {
				case "Agricultural Sciences":
					setContextOfEducationalPoliciesId(1);
					break;
				case "Humanities":
					setContextOfEducationalPoliciesId(2);
					break;
				case "Interdisciplinary sciences":
					setContextOfEducationalPoliciesId(3);
					break;
				case "Medical & Health Sciences":
					setContextOfEducationalPoliciesId(4);
					break;
				case "Natural Sciences":
					setContextOfEducationalPoliciesId(5);
					break;
				case "Social sciences":
					setContextOfEducationalPoliciesId(6);
					break;	
				case "Technical sciences":
					setContextOfEducationalPoliciesId(7);
					break;
			}
	}

	React.useEffect(() => {
		if (!isReligious) {
			setValue("fk_religiousInstitutionType", null);
		} else {
			setValue("fk_religiousInstitutionType", religiousTypeId);
		}
	}, [isReligious]);

	const handleCheckbox = () => {
		setIsFaculty(!isFaculty);
		setValue("isFaculty", !isFaculty); 
		setModal(false);
	}

	const handleModal = () => {
		if (!isFaculty) {
			setModal(true);
		} else {
			setIsFaculty(!isFaculty);
			setValue("isFaculty", !isFaculty); 
		}
	}
	
	const handleModalClose = () => {
		setModal(false);
	}

	//	Reset form data
	const resetData = () => {
		let institution = {
			nameOfTheInstitution: "",
			academicAreaId: "",
			faculty: "",
			department: "",
			contextOfEducationalPoliciesId: "",
			institutionTypeId: "",
			religiousTypeId: "",
			higherEducationInstitutionType: "",
			isFaculty: false,
			isReligious: false,
		};

		//	Save draft to localStorage
		if (!id)
			localStorage.setItem('hejde_institution_details', JSON.stringify(institution));

		setInstitutionName("");
		setAcademicArea(null);
		setIsFaculty(false);
		setFaculty("");
		setDepartment("");
		setContextOfEducationalPoliciesId(null);
		setInstitutionTypeId(null);
		setIsReligious(false);
		setReligiousTypeId(null);
		setHigherEducationInstitutionType(null);
	}

	//	Load values from database upon initialization (if editin)
	if (institutionData && !initialized) {
		resetData();
		setInitialized(true);
	}

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Institution information
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField
							inputRef={register({ required: true })}
							required
							id="nameOfTheInstitution"
							name="nameOfTheInstitution"
							label="Institution name"
							fullWidth
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setInstitutionName(e.target.value) }}
							value={nameOfTheInstitution}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl required fullWidth>
							<InputLabel variant="outlined">Institution area</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								name="fk_academicArea"
								defaultValue={academicAreaId ? academicAreaId : ""}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select 
											onChange={(e: React.ChangeEvent<{value: unknown}>) => { setAcademicArea(e.target.value as string); setValue("fk_academicArea", e.target.value as string); }}
											onBlur={onBlur} 
											value={academicAreaId ? academicAreaId : ""} 
											name="fk_academicArea"
										>
											{institutionStore?.institution?.academicArea?.map((institution) => (
												<MenuItem key={institution.academicAreaId} value={institution.academicAreaId}>
													{institution.academicAreaName}
												</MenuItem>
											))}
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={2}>
						<FormControl fullWidth style={{ marginTop: "5px", }}>
							<Controller
								type="checkbox"
								variant="outlined"
								control={control}
								refs={register}
								name="isFaculty"
								defaultValue={isFaculty}
								render={({ onChange, onBlur, value }) => {
									return (
										<FormControlLabel
											control={<Checkbox checked={isFaculty} onChange={() => { handleModal(); setIsChanged(true); }} name="isFaculty" color="primary" />}
											label="The institution has no faculty"
										/>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							inputRef={register({ required: !isFaculty })}
							required
							id="faculty"
							name="faculty"
							label="Faculty name"
							fullWidth
							disabled={isFaculty}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setFaculty(e.target.value); setIsChanged(true); }}
							value={faculty}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							inputRef={register}
							id="department"
							name="department"
							label="Department name"
							fullWidth
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setDepartment(e.target.value); setIsChanged(true); }}
							value={department}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl required fullWidth>
							<InputLabel variant="outlined">Context of educational policies</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								refs={register({ required: true })}
								name="fk_contextOfEducationalPolicies"
								defaultValue={contextOfEducationalPoliciesId ? contextOfEducationalPoliciesId : ""}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select 
											onChange={(e: React.ChangeEvent<{value: unknown}>) => { setContextOfEducationalPoliciesId(e.target.value as string); setValue("fk_contextOfEducationalPolicies", e.target.value as string); setIsChanged(true); }}
											onBlur={onBlur} 
											value={contextOfEducationalPoliciesId ? contextOfEducationalPoliciesId : ""} 
											name="fk_contextOfEducationalPolicies"
										>
											{institutionStore?.institution?.contextOfEducationalPolicies?.map((institution) => (
												<MenuItem key={institution.contextOfEducationalPoliciesId} value={institution.contextOfEducationalPoliciesId}>
													{institution.contextOfEducationalPoliciesName}
												</MenuItem>
											))}
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl required fullWidth>
							<InputLabel variant="outlined">Institution ownership</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								refs={register({ required: true })}
								name="fk_institutionType"
								defaultValue={institutionTypeId ? institutionTypeId : ""}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select 
											onChange={(e: React.ChangeEvent<{value: unknown}>) => { setInstitutionTypeId(e.target.value as string); setValue("fk_institutionType", e.target.value as string); setIsChanged(true); }}
											onBlur={onBlur} 
											value={institutionTypeId ? institutionTypeId : ""} 
											name="fk_institutionType"
										>
											{institutionStore?.institution?.institutionType?.map((institution) => (
												<MenuItem key={institution.institutionTypeId} value={institution.institutionTypeId}>
													{institution.institutionTypeName}
												</MenuItem>
											))}
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={1}>
						<FormControl fullWidth>
							<Controller
								type="checkbox"
								variant="outlined"
								control={control}
								refs={register}
								name="isReligious"
								defaultValue={isReligious}
								render={({ onChange, onBlur, value }) => {
									return (
										<FormControlLabel
											control={<Checkbox checked={isReligious} onChange={() => { setValue("isReligious", !isReligious); setIsReligious(!isReligious); setIsChanged(true); }} name="isReligious" color="primary" />}
											label="Religious institution"
										/>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={5}>
						<FormControl fullWidth className="institution-form-religious-institution-dropdown">
							<InputLabel variant="outlined">Religious institution type</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								refs={register({ required: true })}
								name="fk_religiousInstitutionType"
								defaultValue={religiousTypeId ? religiousTypeId : ""}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select 
											onChange={(e: React.ChangeEvent<{value: unknown}>) => { setReligiousTypeId(e.target.value as number); setValue("fk_religiousInstitutionType", e.target.value as string); setIsChanged(true); }}
											onBlur={onBlur}
											value={religiousTypeId ? religiousTypeId : ""}
											name="fk_religiousInstitutionType"
											disabled={!isReligious}
										>
											<MenuItem key="1" value="1">Public religious</MenuItem>
											<MenuItem key="2" value="2">Private religious</MenuItem>
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl required fullWidth>
							<InputLabel>Higher-education institution type</InputLabel>
							<Controller
								type="select"
								variant="outlined"
								control={control}
								refs={register({ required: true })}
								name="fk_higherEducationInstitutionType"
								defaultValue={higherEducationInstitutionType ? higherEducationInstitutionType : ""}
								render={({ onChange, onBlur, value }) => {
									return (
										<Select 
											onChange={(e: React.ChangeEvent<{value: unknown}>) => { setHigherEducationInstitutionType(e.target.value as string); setValue("fk_higherEducationInstitutionType", e.target.value as string); setIsChanged(true); }}
											onBlur={onBlur}
											value={higherEducationInstitutionType ? higherEducationInstitutionType : ""}
											name="fk_higherEducationInstitutionType"
										>
											{institutionStore?.institution?.higherEducationInstitutionType?.map((institution) => (
												<MenuItem key={institution.higherEducationInstitutionTypeId} value={institution.higherEducationInstitutionTypeId}>
													{institution.higherEducationInstitutionTypeName}
												</MenuItem>
											))}
										</Select>
									);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Grid container alignItems="center">
							<Grid item xs={9}>
								<Typography style={{ fontStyle: "italic" }}>
									{id ? "" : "Data is saved automatically, allowing you to continue working on your entry at a later date."}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Box display="flex" p={2} flexDirection="row-reverse" style={{ paddingRight: "0px" }}>
									<Button type="submit" variant="contained" color="primary" style={{ marginLeft: "12px" }}>
										Next
									</Button>

									{/*
										<Button type="button" variant="outlined" color="primary" onClick={() => resetData()}>
											Reset
										</Button>
									*/}
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>

			<Snackbar open={yearModal} autoHideDuration={6000} onClose={() => { setYearModal(false); }}>
				<Alert onClose={() => { setYearModal(false); }} severity="error">
					This field only accepts numbers.
				</Alert>
			</Snackbar>

			<Modal
				open={modal}
				onClose={handleModalClose}
				closeAfterTransition

				className="modal-confirmation"
			>
				<Fade in={modal}>
					<div className="modal-confirmation-container">
						<div className="modal-confirmation-icon-close-container">
							<IconClose
								className="modal-confirmation-icon-close"
								onClick={handleModalClose}
							/>
						</div>
						<div className="modal-confirmation-icon-container">
							<ErrorOutlineIcon color="secondary" style={{ fontSize: 50 }} />
						</div>
						<div className="modal-confirmation-text-container">
							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-title"
							>
								You are about to disable a text field
							</Typography>

							<Typography
								variant="body2"
								color="textSecondary"
								className="modal-confirmation-description"
							>
								Are you absolutely sure this instituion has no faculty?
							</Typography>
						</div>
						<Grid
							container
							direction="row"
							className="modal-confirmation-actions-container"
							spacing={1}
						>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-confirm-container"
							>
								<Button
									size="small"
									variant="contained"
									className="delete-button"
									onClick={handleCheckbox}
								>
									Yes, I am sure
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className="modal-confirmation-actions-cancel-container"
							>
								<Button
									size="small"
									variant="outlined"
									color="primary"
									className="cancel-button"
									onClick={handleModalClose}
								>
									Cancel
								</Button>
							</Grid>
						</Grid>
					</div>
				</Fade>
			</Modal>
		</React.Fragment>
	);
});
export default InstitutionForm;
